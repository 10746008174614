import { useEffect, useRef, useState } from "react"

type NaverMapProps ={
    longitude:number
    latitude:number
    zoomLevel?:number
    zoomControl?:boolean
    isReverse?:boolean
    handleReserveGpsResponse?:(addressList:any)=>{}
}

const NaverMap = ({longitude, latitude, zoomLevel,zoomControl,isReverse, handleReserveGpsResponse}:NaverMapProps)=>{
 
    const mapElement = useRef(null);
    const [coord,setCoord] = useState({x:longitude,y:latitude})
    const reserve = isReverse??false

    
    const mHandleReserveGpsResponse= (result:any)=>{
        if(handleReserveGpsResponse){
            handleReserveGpsResponse(result)
        }
    }

    useEffect(()=>{

        let mZoomLevel = zoomLevel ?? 17
        let mZoomControl = zoomControl ?? false
        
        if (!mapElement.current || !naver) return;

        // 지도에 표시할 위치의 위도와 경도 좌표를 파라미터로 넣어줍니다.
        const naverLocation = new naver.maps.LatLng(latitude, longitude);
        
        const mapOptions:naver.maps.MapOptions = {
            center: naverLocation,
            zoom: mZoomLevel,
            zoomControl: mZoomControl,
            scaleControl:false,
            scrollWheel:false,
            zoomControlOptions: {
                position: naver.maps.Position.TOP_RIGHT,
                }
            }
        const map = new naver.maps.Map(mapElement.current, mapOptions);
        const marker = new naver.maps.Marker({
            position: naverLocation,
            map,
            })
        if(reserve){
            naver.maps.Event.addListener(map,'click',(e:any)=>{
                setCoord({...coord,x:e.coord.x,y:e.coord.y})
                marker.setPosition(new naver.maps.Point(e.coord.x,e.coord.y))
            })
        }

        
    }, [])

    useEffect(()=>{

        let mZoomLevel = zoomLevel ?? 17
        let mZoomControl = zoomControl ?? false
        
        if (!mapElement.current || !naver) return;

        // 지도에 표시할 위치의 위도와 경도 좌표를 파라미터로 넣어줍니다.
        const naverLocation = new naver.maps.LatLng(latitude, longitude);
        
        const mapOptions:naver.maps.MapOptions = {
            center: naverLocation,
            zoom: mZoomLevel,
            zoomControl: mZoomControl,
            scaleControl:false,
            scrollWheel:false,
            zoomControlOptions: {
                position: naver.maps.Position.TOP_RIGHT,
                }
            }
        const map = new naver.maps.Map(mapElement.current, mapOptions);
        const marker = new naver.maps.Marker({
            position: naverLocation,
            map,
            })
        setCoord({...coord,x:longitude,y:latitude})
        marker.setPosition(new naver.maps.Point(longitude,latitude))
    },[latitude,longitude])

    useEffect(()=>{
        console.log('useEffect coord',coord)
        if(reserve){
            naver.maps.Service.reverseGeocode({
                coords: new naver.maps.LatLng({y:coord.y,x:coord.x}),
                orders: [
                    naver.maps.Service.OrderType.ADDR,
                    naver.maps.Service.OrderType.ROAD_ADDR
                ].join(',')
            }, function(status, response) {
                if (status === naver.maps.Service.Status.ERROR) {
                    return alert('Something Wrong!');
                }
                let result:any = response
                result.latitude = coord.y
                result.longitude = coord.x
                mHandleReserveGpsResponse(result)
            });
        }
    },[coord])

    console.log('navermap rerender')
    return(
    <div ref={mapElement} style={{height:'100%',width:'100%',outline:'none',}} />
    )
}

export default NaverMap