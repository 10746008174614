
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { runInAction } from 'mobx';
import { Swiper, SwiperSlide } from "swiper/react";
import { Dialog } from '@mui/material';
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import home from '../store/home';
import pulse from './pulse'; 
import PortfolioDialog from './PortfolioDialog';
import "swiper/swiper.min.css"; 
import './pulse.css'
import '../main.css'
import '../swiper.css'

SwiperCore.use([Autoplay])
const SwiperPortfolio = observer((props)=>{
 

    const [pageNumber,setPageNumber] = useState(0) 
    const [portfolioDialog,setPortfolioDialog] = useState(false) 
   
   
    useEffect(()=>{
        return(()=>{
            window.onpopstate = {}
        })
    },[])

    useEffect(()=>{
        if(portfolioDialog){
            window.history.pushState({ page: 1 }, "title 1", "?page=1")
        }
        window.onpopstate = handleOnBack
    },[portfolioDialog])

    const handleOnBack = ()=>{
        setPortfolioDialog(false)
    }
 
    

    const handlePortfolioDialogOpen = (val) => {
        setPortfolioDialog(true)
        runInAction(()=>{
            pulse.portfolio.val = val
        })
    }
   

    const handlePortfolioDialogClosed = (isOpen) => {
        setPortfolioDialog(false)
    }
  
    const h = home.pageWidth==='mobile'?80:120

    return(
        <div className='swiperMainLayout'>

            <div style={{minHeight:h,maxHeight:h,height:h}}/>
                
            <div className='pageWidthFullSize' style={{height:'100%',maxHeight:'900px',display:'flex',alignItems:'center'}}>

                <Swiper  
                modules={[Navigation, Pagination]} 
                className='portfolioSwiper'
                pagination={
                    {el: ".swiper-pagination", 
                    type : 'bullets'}
                }  
                slidesPerView={home.pageWidth==='mobile'?1:home.pageWidth==='pc'?2:3} 
                spaceBetween={40}
                initialSlide={0}> 

                    {/* 이미지비율 100 : 80 */}

                    {props.list.map((val,idx)=>
                        <SwiperSlide key={idx} onClick={()=>handlePortfolioDialogOpen(val)} >
                            <div style={{minWidth:'100%',minHeight:'100%'}}>
                                <img  style={{width:'100%',maxWidth:'100%',height:'auto', objectFit:'cover'}} src={val.MainImg} alt='' />
                                <div className='korTextSize2' style={{fontWeight:600,margin:'20px 0px 5px 0px',color:'#323232',textAlign:'left',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <div style={{width:'100%'}}>
                                        {val.Name} 
                                    </div>
                                    {val.Os.map((vval,vidx)=>
                                        <img src={vval} alt='' key={vidx} style={{width:25,height:25,marginLeft:5}}/>    
                                    )}
                                </div>
                                <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'left'}}> 
                                    {val.Contents} 
                                </div>        
                            </div>
                        </SwiperSlide>
                    )}

                    {/* {props.list.map((val,idx)=>
                        <SwiperSlide key={idx} onClick={()=>handlePortfolioDialogOpen(val)} >
                            <div style={{minWidth:'100%',minHeight:'100%'}}>
                                <img  style={{width:'100%',maxWidth:'100%',height:'auto', objectFit:'cover'}} src={val.MainImg} alt='' />
                            
                            
                                <div className='korTextSize2' style={{fontWeight:500,margin:'20px 0px 5px 0px',color:'#323232',textAlign:'left',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                
                                    
                                    <div style={{width:'100%'}}>
                                        {val.Name} 
                                    </div>

                                    {val.Os.map((vval,vidx)=>
                                        <img src={vval} alt='' key={vidx} style={{width:25,height:25,marginLeft:5}}/>    
                                    )}
                                </div>


                                <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'left'}}> 
                                    {val.Contents} 
                                </div>

                                
        
                            </div>
                        </SwiperSlide>
                    )} */}
                
                </Swiper> 
            </div>
     

            <div style={{width:'100%',flex:1,boxSizing:'border-box',flexDirection:'column', display:'flex',alignItems:'center',justifyContent:'center',minHeight:h,maxHeight:h,height:h}}>
                <div className='swiper-pagination'/>
                <div style={{height:30,minHeight:30}}></div>
            </div>


            <Dialog className={home.widthClassname} open={portfolioDialog} onClick={()=>setPortfolioDialog(false)}>
                <PortfolioDialog handlePortfolioDialogClosed={handlePortfolioDialogClosed}/>
            </Dialog>
                  
        </div>
        )
    })

export default SwiperPortfolio