import './businesscard.css'
import photoBg from '../resource/inbumBg.jpg'
import photo from '../resource/inbum.jpg'
import Logo from '../resource/pulselogo_ffffff.svg'
import Call from '../resource/call.svg'
import Email from '../resource/email.svg'

const Inbum = ()=>{
 

 
    return(
        <div style={{width:'100vw',height:'100vh',alignItems:'center',justifyContent:'center',display:'flex',flex:1,position:'relative',color:'#ffffff',fontSize:14}}>
            <img style={{width:'120vw',height:'120vh',position:'absolute',objectFit:'cover',filter: 'blur(10px)'}} alt='' src={photoBg}/>
            
            <img alt='' src={photo} style={{position:'absolute',width:'85%',maxWidth:500,maxHeight:'auto',minHeight:'80%',display:'flex',flexDirection:'column',
                 borderRadius:5,border:'1px solid #00000050',objectFit:'cover'}}/>

            <div style={{position:'absolute',width:'85%',maxWidth:500,maxHeight:'auto',minHeight:'80%',display:'flex',flexDirection:'column',
                 borderRadius:10,textAlign:'right',alignItems:'flex-end',padding:20,boxSizing:'border-box',justifyContent:'flex-end',zIndex:3000}}>
                    
                    <div style={{fontWeight:700,fontSize:22,}}>
                        이 인 범
                    </div>

                    <div style={{color:'#ffffffcc'}}>
                        대표
                    </div>
    
                    <div style={{display:'flex',flexDirection:'row',paddingTop:20,paddingBottom:5}}>
                        <div style={{paddingRight:5,fontWeight:700}}>E. </div>
                        <div>leeinbum@gmail.com</div>
                    </div>

                    <div style={{display:'flex',flexDirection:'row'}}>
                        <div style={{paddingRight:5,fontWeight:700}}>M. </div>
                        <div>010-9486-1351</div>
                    </div>
 

                    <div style={{marginTop:15,marginBottom:15,width:30,height:3,borderRadius:10,backgroundColor:"#ffffff"}}/>
                    <div style={{color:'#ffffffcc',}}>
                        
                        안녕하세요. PULSE 대표 이인범입니다.

                    </div>
    

                    <div style={{display:'flex',flexDirection:'row',paddingTop:20}}>
                        <a href="tel:010-9486-1351"><img src={Call} alt='' style={{width:50,height:50,marginRight:10}}/></a>
                        <a href="sms:010-9486-1351"><img src={Email} alt='' style={{width:50,height:50}}/></a>
                    </div>
            </div>

            <div style={{position:'absolute',height:'100%',width:'100%',display:'flex',alignItems:'flex-end',justifyContent:'center'}}>
                <div style={{height:'10%',width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <img style={{height:'auto',width:'80px',}} alt='' src={Logo}/>
                </div>
            </div>
    
        </div>)
}

export default Inbum