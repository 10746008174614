import '../main.css' 
import { useEffect, useState } from 'react';
import downBtn from '../resource/downloadbtn1-2.svg';
import downBtn2 from '../resource/downloadbtn2-2.svg';


import home from '../store/home';
import imgLogo from '../resource/doubleportion/doubleportionLogo_ee8432.svg';
import video1 from '../resource/doubleportion/doubleportionMainWh.mp4';


import videoDelivery from '../resource/doubleportion/appInforVideo1.mp4'; 
import videoBus from '../resource/doubleportion/appInforVideo2.mp4';
import videoShow from '../resource/doubleportion/appInforVideo3.mp4';

import imgGiftCard from '../resource/doubleportion/giftcard.png'
import './doubleportion.css'
import '../swiper.css'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay, Mousewheel } from "swiper";

import imgArrowDown from '../resource/arrowdown_ffffff.svg'
import imgArrowUp from '../resource/arrowup_ffffff.svg'

import { useNavigate } from 'react-router';

import mainVideo from '../resource/doubleportion/mainPhoneVideo.mp4'
 

SwiperCore.use([Autoplay])
SwiperCore.use([Mousewheel])

const DoublePortion = ()=>{


    useEffect(()=>{
        deliveryPlay(0,'over')
    },[])

    const [pageNumber,setPageNumber] = useState(0)
    const [swiper,setSwiper] = useState(null)
    const navigate = useNavigate()

    const [bizInfo,setBizInfo] = useState(false)

    useEffect(()=>{
        setPageNumber(0)
    },[])

    function deliveryPlay(idx,state) {
        let video0 = document.getElementById("video0");
        let video1 = document.getElementById("video1");
        let video2 = document.getElementById("video2");

        if(state==='over'){
            if(idx===0){
                video0.play();
                video1.pause();
                video2.pause();
            }else if(idx===1){
                video0.pause();
                video1.play();
                video2.pause();
            }else{
                video0.pause();
                video1.pause();
                video2.play();
            }
        }else{
            video0.pause();
            video1.pause();
            video2.pause();
        }      
    }
    useEffect(()=>{
        let bulletColor = `#323232`
        let bullet
        if(pageNumber!==3){
            bulletColor = `#323232`
        }else{
            bulletColor = `#ffffff`
        }
        document.documentElement.style.setProperty('--bulletColor', bulletColor);
    },[pageNumber])

  
    function nbsp(){
        let text = ' '
        if(home.pageWidth!=='mobile'){
            text = '\u00A0'
        }else{
            text = ''
        }

        return text
    }
    
    const h = home.pageWidth==='mobile'?60:80
    
    return(
    <div className='mainLayout' style={{color:'#323232',flexDirection:'row',position:'relative'}}>
        <div className='topMenuLayout'style={{userSelect:'none'}}>
           {pageNumber===3?<></>:
            <div className='pageWidthFullSize'>
                <img src= {imgLogo} alt='' className='doubleportionMainLogo' onClick={()=>swiper.slideTo(0)}/>
                <div className={'doubleportionMenuBar'}> 
                  {pageNumber!==4? 
                    <div className='doubleportionMenu' onClick={()=>swiper.slideTo(3)}>
                       APP 다운로드
                        {/* <img src={imgDownload} alt='' style={{width:15,height:15,marginLeft:5}}/> */}
                    </div>  :<></>}
                </div>       
            </div>}
        </div>

        <Swiper     
        onSwiper = {setSwiper}
        style={{top:0,left:0,width:'100vw',height:'100%'}}
            modules={[Navigation, Pagination, ]} 
            direction='vertical' 
            speed={1000}
            mousewheel= {true}
            pagination={
                {el: ".swiper-pagination", 
                type : 'bullets',
                clickable: true,}
            }  
            onSlideChange={(e) =>  
                setPageNumber(e.realIndex)
            }
            autoHeight={true} 
            slidesPerView={1} 
            initialSlide={0}                          
            loop={false}
            loopedSlides={1}
            observer={true}
            observeParents={true}
            resistance={true} >
            
                
                {/* <SwiperSlide> 
                    <div className='doubleportionMainPage' style={{position:'relative'}}>

                    
                  
                    <div style={{position:'absolute',width:'100vw',height:'100%' ,top:0,left:0,zIndex:900}}/>

                        <div className='pageWidthFullSize' style={{position:'absolute',height:'100%',maxHeight:'100%',zIndex:1100,
                        display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-end',boxSizing:'border-box',width:'100%',maxWidth:1400,}}>
                            
                            <div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column',alignItems:'center',
                                 justifyContent:'center',boxSizing:'border-box', textAlign:'left'}}>
                                <div className='korTextSize5 fontNanumSquareExtraBold'
                                    style={{padding:'20px 0px 0px 0px',display:'flex',alignItems:'center',justifyContent:'center',color:'#fff',flexDirection:'column',lineHeight:1.3,}}>
                                        <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',wordBreak:'break-word',alignItems:'center',justifyContent:'center',textAlign:'center',width:'100vw',}}>
                                            <div>다양한 컨텐츠로</div>
                                        </div>
                                        <div style={{position:'relative',width:'100vw',display:'flex',alignItems:'center',justifyContent:'center',height:70}}>
                                            <div style={{position:'absolute'}} className='one'>편리함도</div>
                                            <div style={{position:'absolute'}} className='two'>즐거움도</div>
                                            <div style={{position:'absolute'}} className='three'>혜택도</div> 
                                        </div> 
                                        <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',wordBreak:'break-word',alignItems:'center',justifyContent:'center',textAlign:'center',width:'100vw',}}>
                                            곱빼기 하세요
                                        </div> 

                                        <div style={{display:'flex',width:'80%',flexDirection:'row', alignItems:'center',justifyContent:'center', boxSizing:'border-box',marginTop:h}}>
                                            <div  style={{width:'50%',maxWidth:180,display:'flex',alignItems:'center',justifyContent:'center',height:'auto', }}>
                                                <img src={downBtn} onClick={()=>window.open('https://play.google.com/store/apps/details?id=tc.wo.pulse.community')} alt ='' style={{width:'100%',maxWidth:160,height:'auto',padding:'0px 15px 0px 10px',boxSizing:'border-box'}}/>
                                            </div>
                                            <div  style={{width:'50%',maxWidth:180,display:'flex',alignItems:'center',justifyContent:'center',height:'auto', }}>
                                                <img src={downBtn2} onClick={()=>window.open('https://apps.apple.com/kr/app/%EA%B3%B1%EB%B9%BC%EA%B8%B0/id1621478258')} alt ='' style={{width:'100%',maxWidth:160,height:'auto',padding:'0px 10px 0px 15px',boxSizing:'border-box'}}/>
                                            </div>                                
                                        </div> 
                                </div>

                                <div style={{height:h/2,maxHeigh:h/2,minHeight:h/2}}/>

                                 
                            </div>


                             




                           
                        </div>
                    </div>
                </SwiperSlide>  */}
 


                <SwiperSlide> 
                    <div className='doubleportionMainPage' style={{backgroundColor:'#ffffff',position:'relative'}}>

                      

                        <div className='pageWidthFullSize' style={{position:'absolute',height:'100%',maxHeight:'100%',
                        display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-end',boxSizing:'border-box',width:'100%',maxWidth:1400,lineHeight:1.3}}>


                            <div style={{height:'100%',maxHeight:'100%',paddingTop:h,width:'100%',maxWidth:'100%',minWidth:'fit-content',display:'flex',alignItems:'center',justifyContent:'flex-start',boxSizing:'border-box',
                                flexDirection:'column',overflow:'hidden'}}>                              
                                <div style={{width:'100%',minWidth:'100%',maxWidth:'100%',height:'100%',display:'flex',flexDirection:'column',alignItems:'flex-start',justifyContent:'center',textAlign:'left',}}>                                    
                                    <div style={{width:'100%',height:'auto',minHeight:250,display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',boxSizing:'border-box',textAlign:'center'}}>
                                        <div className='korTextSize4 fontNanumSquareExtraBold'
                                        style={{display:'flex',alignItems:'center',justifyContent:'center',color:'#5d5d5d',flexDirection:home.pageWidth==='mobile'?'column':'column'}}>
                                            <div className='fontNanumSquare' style={{minWidth:'fit-content',}}>소비자와 소상공인이</div>
                                            {/* {nbsp()} */}
                                            <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',wordBreak:'break-word',alignItems:'center',justifyContent:'center',textAlign:'center',width:'100%',}}>
                                                함께 누리는 행복
                                            </div> 
                                        </div>

                                        <div style={{height:'3vh',maxHeigh:'3vh',minHeight:'3vh'}}/>

                                        <div className='textSize1' style={{display:'flex',alignItems:'center',justifyContent:'center',color:'#969696',flexDirection:home.pageWidth==='mobile'?'column':'row'}}>                    
                                            <div>소비자들의 만족은 곱하고,</div>
                                            {nbsp()}
                                            <div>소상공인들의 부담은 덜다</div> 
                                        </div>
                                    </div> 
                                </div>


                                <div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center',paddingBottom:h,paddingTop:'3vh'}}>
                                    <video 
                                        style={{width:'100%',
                                        height:'100%',
                                        maxWidth:700,maxHeight:700,outline:0,border:0,clipPath:'inset(1px 1px)',}} 
                                        loop
                                        autoPlay
                                        muted
                                        >
                                        <source src={video1} type="video/mp4"/>
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide> 

 

              
                <SwiperSlide> 
                    <div className='doubleportionMainPage' style={{backgroundColor:'#ffffff',position:'relative',}}>

                      

                        <div className='pageWidthFullSize' style={{position:'absolute',height:'100%',maxHeight:'100%', 
                        display:'flex',flexDirection:'row',alignItems:'center', boxSizing:'border-box',width:'100%',maxWidth:1400,lineHeight:1.3}}>


                            <div style={{height:'100%',maxHeight:'100%',width:'100%',maxWidth:'100%',minWidth:'fit-content',display:'flex',alignItems:'center',justifyContent:'center',boxSizing:'border-box',
                                flexDirection:'column', }}>                              
                                <div style={{width:'100%',minWidth:'100%',maxWidth:'100%',display:'flex',flexDirection:'column',alignItems:'flex-start',justifyContent:'center',textAlign:'left',}}>                                    
                                    <div style={{width:'100%',height:'auto',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',boxSizing:'border-box',textAlign:'center'}}>
                                        <div className='korTextSize4 fontNanumSquareExtraBold'
                                        style={{display:'flex',alignItems:'center',justifyContent:'center',color:'#5d5d5d',flexDirection:home.pageWidth==='mobile'?'column':'column'}}>
                                            <div className='fontNanumSquare' style={{minWidth:'fit-content'}}>곱빼기로 누리는</div> 
                                            <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',wordBreak:'break-word',alignItems:'center',justifyContent:'center',textAlign:'center',width:'100%',}}>
                                                 펀하고 편한 일상
                                            </div> 
                                        </div>

                                        <div style={{height:'3vh',maxHeigh:'3vh',minHeight:'3vh'}}/>

                                        <div className='textSize1' style={{display:'flex',alignItems:'center',justifyContent:'center',color:'#969696',flexDirection:home.pageWidth==='mobile'?'column':'row'}}>                    
                                            <div>배달서비스 / 버스도착정보 / 문화생활 등</div>
                                            {nbsp()}
                                            <div>다양한 기능을 제공합니다</div> 
                                        </div>
                                    </div> 
                                </div>
                                
                                <div style={{height:h+20,minHeight:h+20}}/>


                                <div className='pageWidthFullSize' style={{padding:home.pageWidth==='mobile'?'0px':'',display:'flex',flexDirection:'row',justifyContent:'center',maxWidth:1400,
                                    alignItems:'center', boxSizing:'border-box',height:'auto',minHeight:'fit-content',}}>

                                        <Swiper  
                                            modules={[Navigation, Pagination]} 
                                            className='doubleportionSwiper'
                                            slidesPerView={home.pageWidth==='mobile'?1.5:3} 
                                            spaceBetween={10} 
                                            centeredSlides={home.pageWidth==='mobile'?true:false}
                                            initialSlide={0}
                                            onSlideChange={(e) =>  
                                                deliveryPlay(e.realIndex,'over')
                                            }> 

                                            {/* 이미지비율 100 : 80 */}
                    
                                                <SwiperSlide>
                                                    <div style={{width:'95%',alignItems:'center',justifyContent:'center',display:'flex',flex:1,position:'relative',color:'#969696',
                                                        boxSizing:'border-box',flexDirection:'column',backgroundColor:"#ffffff",}}>
                                                        <video
                                                            id='video0'
                                                            className='appContentsVideo' 
                                                            onClick={()=>deliveryPlay(0,'over')} 
                                                            onMouseOver={()=>deliveryPlay(0,'over')}
                                                            onMouseOut={()=>deliveryPlay(0,'out')}
                                                            loop
                                                            muted>
                                                            <source src={videoDelivery} type="video/mp4"/>
                                                        </video> 
                                                        <div style={{display:'flex',flexDirection:'column',width:'95%'}}>
                                                            <div className='textSize1' style={{margin:'30px 0px 10px 0px',color:'#ee8432',fontWeight:700}}>배달서비스</div>
                                                            <div className='korTextSize1'>간편하게 집에서 다양한 음식들을 즐겨보세요</div>
                                                        </div>
                                                        
                                                    </div>
                                                </SwiperSlide> 

                                                <SwiperSlide>
                                                    <div style={{width:'95%',alignItems:'center',justifyContent:'center',display:'flex',flex:1,position:'relative',color:'#969696',
                                                        boxSizing:'border-box',flexDirection:'column',backgroundColor:"#ffffff",}}>
                                                        <video 
                                                            id='video1'
                                                            className='appContentsVideo' 
                                                            onClick={()=>deliveryPlay(1,'over')} 
                                                            onMouseOver={()=>deliveryPlay(1,'over')}
                                                            onMouseOut={()=>deliveryPlay(1,'out')}
                                                            loop
                                                            muted>
                                                            <source src={videoBus} type="video/mp4"/>
                                                        </video> 
                                                        <div style={{display:'flex',flexDirection:'column',width:'95%'}}>
                                                            <div className='textSize1' style={{margin:'30px 0px 10px 0px',color:'#ee8432',fontWeight:700}}>버스도착정보</div>
                                                            <div className='korTextSize1'>실시간으로 버스도착정보를 받아볼 수 있어요</div>
                                                        </div>
                                                        
                                                    </div>
                                                </SwiperSlide> 

                                                <SwiperSlide>
                                                    <div style={{width:'95%',alignItems:'center',justifyContent:'center',display:'flex',flex:1,position:'relative',color:'#969696',
                                                        boxSizing:'border-box',flexDirection:'column',backgroundColor:"#ffffff",}}>
                                                        <video 
                                                            id='video2'
                                                            className='appContentsVideo' 
                                                            onClick={()=>deliveryPlay(2,'over')} 
                                                            onMouseOver={()=>deliveryPlay(2,'over')}
                                                            onMouseOut={()=>deliveryPlay(2,'out')}
                                                            loop
                                                            muted>
                                                            <source src={videoShow} type="video/mp4"/>
                                                        </video> 
                                                        <div style={{display:'flex',flexDirection:'column',width:'95%'}}>
                                                            <div className='textSize1' style={{margin:'30px 0px 10px 0px',color:'#ee8432',fontWeight:700}}>문화생활</div>
                                                            <div className='korTextSize1'>행사, 공연, 관광명소등 다양한 문화생활 정보를 받아보세요</div>
                                                        </div>
                                                        
                                                    </div>
                                                </SwiperSlide> 
                                        </Swiper>



                                    
                    
                                
                    

                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide> 
               


                
                <SwiperSlide> 
                    <div className='doubleportionMainPage' style={{backgroundColor:'#ffffff',position:'relative',borderBottom:0}}>

                      

                        <div className='pageWidthFullSize' style={{position:'absolute',height:'100%',maxHeight:'100%', 
                        display:'flex',flexDirection:'row',alignItems:'center', boxSizing:'border-box',width:'100%',maxWidth:1400,lineHeight:1.3}}>


                            <div style={{height:'100%',maxHeight:'100%',width:'100%',maxWidth:'100%',minWidth:'fit-content',display:'flex',alignItems:'center',justifyContent:'center',boxSizing:'border-box',
                                flexDirection:'column', }}>                              
                                <div style={{width:'100%',minWidth:'100%',maxWidth:'100%',display:'flex',flexDirection:'column',alignItems:'flex-start',justifyContent:'center',textAlign:'left',}}>                                    
                                    <div style={{width:'100%',height:'auto',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',boxSizing:'border-box',textAlign:'center'}}>
                                        <div className='korTextSize4 fontNanumSquareExtraBold'
                                        style={{display:'flex',alignItems:'center',justifyContent:'center',color:'#5d5d5d',flexDirection:home.pageWidth==='mobile'?'column':'column'}}>
                                            <div className='fontNanumSquare' style={{minWidth:'fit-content'}}>모바일 chak</div> 
                                            <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',wordBreak:'break-word',alignItems:'center',justifyContent:'center',textAlign:'center',width:'100%',}}>
                                                 서산사랑상품권 
                                            </div> 
                                        </div>

                                        <div style={{height:'3vh',maxHeigh:'3vh',minHeight:'3vh'}}/>

                                        <div className='textSize1' style={{display:'flex',alignItems:'center',justifyContent:'center',color:'#969696',flexDirection:home.pageWidth==='mobile'?'column':'row'}}>                    
                                            <div>서산사랑상품권을 이용한</div>
                                            {nbsp()}
                                            <div>온라인 결제가 가능합니다</div> 
                                        </div>
                                    </div> 
                                </div>
                                
                                <div style={{height:h-20,minHeight:h-20}}/>


                                <div className='pageWidthFullSize' style={{padding:home.pageWidth==='mobile'?'0px':'',display:'flex',flexDirection:'row',justifyContent:'center',maxWidth:1400,
                                    alignItems:'center', boxSizing:'border-box',height:'auto',minHeight:'fit-content',}}>
                                    <img src={imgGiftCard} alt='' style={{width:'100%',objectFit:'cover',maxWidth:500,height:'auto'}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide> 

 
                <SwiperSlide> 
                    <div className='doubleportionMainPage' style={{flexDirection:'column',color:'#ffffff',alignItems:'center',justifyContent:'center',borderBottom:0}}>
                        

                        <div style={{height:'100%',width:'100%',position:'absolute',zIndex:1100,display:'flex',flexDirection:'column',left:0,top:0,alignItems:'center',justifyContent:'flex-end'}}>
                            <div className='pageWidthFullSize' style={{ 
                            display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-end',boxSizing:'border-box',width:'100%',maxWidth:1400,}}>
                                
                                <div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column',alignItems:'center',
                                    justifyContent:'center',boxSizing:'border-box', textAlign:'left',}}>
                                    <div className='korTextSize5 fontNanumSquareExtraBold'
                                        style={{display:'flex',alignItems:'center',justifyContent:'center',color:'#fff',flexDirection:'column',lineHeight:1.3,}}>
                                            <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',wordBreak:'break-word',alignItems:'center',justifyContent:'center',textAlign:'center',width:'100vw',}}>
                                                <div>다양한 컨텐츠로</div>
                                            </div>
                                            <div style={{position:'relative',width:'100vw',display:'flex',alignItems:'center',justifyContent:'center',height:70}}>
                                                <div style={{position:'absolute'}} className='one'>편리함도</div>
                                                <div style={{position:'absolute'}} className='two'>즐거움도</div>
                                                <div style={{position:'absolute'}} className='three'>혜택도</div> 
                                            </div> 
                                            {/* {nbsp()} */}
                                            <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',wordBreak:'break-word',alignItems:'center',justifyContent:'center',textAlign:'center',width:'100vw',}}>
                                                곱빼기 하세요
                                            </div> 

                                            <div style={{display:'flex',width:'80%',flexDirection:'row', alignItems:'center',justifyContent:'center', boxSizing:'border-box',marginTop:50}}>
                                                <div  style={{width:'50%',maxWidth:180,display:'flex',alignItems:'center',justifyContent:'center',height:'auto', }}>
                                                    <img src={downBtn} onClick={()=>window.open('https://play.google.com/store/apps/details?id=tc.wo.pulse.community')} alt ='' style={{width:'100%',maxWidth:160,height:'auto',padding:'0px 10px 0px 10px',boxSizing:'border-box'}}/>
                                                </div>
                                                <div  style={{width:'50%',maxWidth:180,display:'flex',alignItems:'center',justifyContent:'center',height:'auto', }}>
                                                    <img src={downBtn2} onClick={()=>window.open('https://apps.apple.com/kr/app/%EA%B3%B1%EB%B9%BC%EA%B8%B0/id1621478258')} alt ='' style={{width:'100%',maxWidth:160,height:'auto',padding:'0px 10px 0px 10px',boxSizing:'border-box'}}/>
                                                </div>                                
                                            </div> 
                                    </div> 
                                </div>
                            </div>

                            <div style={{position:'absolute',bottom:0,fontSize:12,lineHeight:1.3,padding:'25px 0px 25px 0px',height:'fit-content',width:'100%',display:'flex',alignItems:'center',justifyContent:'center',
                            backgroundColor:'#12121290',color:'#969696'}}>
                                <div className='pageWidthFullSize' style={{display:'flex',flexDirection:'row', alignItems:'flex-end'}}>
                                    <div style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'flex-start'}}>
                                      

                                        <div onClick={()=>bizInfo?setBizInfo(false):setBizInfo(true)} style={{display:home.pageWidth!=='mobile'?'none':'flex',flexDirection:'row',width:'100%',
                                        flexWrap:'wrap',wordBreak:'break-word',alignItems:'center',justifyContent:'center',textAlign:'left',paddingBottom:10, color:'#dcdcdc'}}>
                                            PULSE 사업자 정보
                                            <img src={bizInfo?imgArrowDown:imgArrowUp} alt='' style={{width:5,height:5,marginLeft:10}}/>
                                        </div>
                                        
                                        <div style={{display:bizInfo || home.pageWidth!=='mobile'?'flex':'none',flexDirection:'row',paddingBottom:10,width:'100%',flexWrap:'wrap',wordBreak:'break-word',alignItems:'center',justifyContent:'center',textAlign:'left'}}>
                                            <div style={{minWidth:'fit-content',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                PULSE 
                                            <div style={{height:10,width:1,backgroundColor:'#5d5d5d',margin:'0px 10px 0px 10px',}}/>
                                            </div>
                                            <div style={{minWidth:'fit-content',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                대표 이인범 (Leeinbum@gmail.com)
                                            <div style={{height:10,width:1,backgroundColor:'#5d5d5d',margin:'0px 10px 0px 10px',}}/>
                                            </div>
                                            <div style={{minWidth:'fit-content',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                사업자번호 501-23-47453
                                            <div style={{height:10,width:1,backgroundColor:'#5d5d5d',margin:'0px 10px 0px 10px',}}/>
                                            </div>
                                            <div style={{minWidth:'fit-content',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                통신판매업 2021-충남서산-0066
                                            <div style={{height:10,width:1,backgroundColor:'#5d5d5d',margin:'0px 10px 0px 10px',}}/>
                                            </div>
                                            <div style={{minWidth:'fit-content',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                충청남도 서산시 안견로 292
                                            <div style={{height:10,width:1,backgroundColor:'#5d5d5d',margin:'0px 10px 0px 10px',}}/>
                                            </div>
                                            <div style={{minWidth:'fit-content',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                            대표전화 : 041-667-5001
                                            </div> 
                                        </div>

                                        <div style={{display:'flex',flexDirection:'row',width:'100%',marginTop:5,color:'#dcdcdc'}}>
                                            <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center',flexWrap:'wrap',wordBreak:'break-word',justifyContent:'center'}}> 
                                                
                                            <div onClick={()=>window.open('../Personalinformation')} style={{minWidth:'fit-content',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                개인정보방침
                                                <div style={{height:10,width:1,margin:'0px 5px 0px 5px', }}/>
                                            </div>
                                            <div onClick={()=>window.open('https://www.ftc.go.kr/bizCommPop.do?wrkr_no=5012347453')}
                                                style={{minWidth:'fit-content',display:'flex',flexDirection:'row',alignItems:'center',}}>
                                                사업자정보확인
                                            </div> 
                                        </div>
                                            
                                        </div> 
                                    </div>


  
                                </div>
                            </div>
                        </div>
 
                       
                    </div>
                </SwiperSlide> 

        </Swiper>

 
        <div style={{boxSizing:'border-box' ,display:home.pageWidth==='mobile'?'none':'flex',alignItems:'center',justifyContent:'center',right:60,height:'100vh',position:'absolute',zIndex:2000}}>
            <div className='swiper-pagination'/>
        </div>


      
            
 
        <video style={{position:'absolute',width:'100vw',height:'100%',borderBottom:'1px solid #ffffff',objectFit:'cover',left:0,top:0,filter:'brightness(0.3) grayscale(1.0)',objectPosition:'center'}}
            className='mainVideoLayout' 
            loop
            autoPlay
            muted
            >
            <source src={mainVideo} type="video/mp4"/>
        </video>     




 
   
    </div>
    
    
    )
    
}

export default DoublePortion