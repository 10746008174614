import axios from 'axios';
import { useEffect, useState } from 'react';
import '../Main/main.css' 
import downBtn from '../resource/downloadbtn.svg';
import downBtn2 from '../resource/downloadbtn2.svg';
 


const Qr = ()=>{

    const [os,setOs] = useState('');
 
    const checkMobile = () => {
        let varUA = navigator.userAgent.toLowerCase(); 
        if(varUA.match('android') != null){
            setOs('android')
            
        }else if(varUA.match("iphone") != null || varUA.match("ipad") != null || varUA.match("ipod") != null ){
            setOs('iphone')
        }else{
            setOs('other')
        }
    }

    useEffect(()=>{
        if(os !==""){
            let postData2 ={
                method:'qrcode',
                data:{
                    method:'capture',
                    os:os
                }
            }
            axios.post('https://seosanez.project.wo.tc/yamyam.php',JSON.stringify(postData2),{ withCredentials: true}).then(
                (response)=>{
                    
                }
            )
        }
    },[os])

    useEffect(()=>{


        checkMobile()
        if(os==='android'){
            window.open('https://play.google.com/store/apps/details?id=tc.wo.pulse.community')
        }else if(os==='iphone'){
            window.open('https://apps.apple.com/kr/app/%EA%B3%B1%EB%B9%BC%EA%B8%B0/id1621478258')
        }
    },[])

    console.log(os,'os')

    return(
    <div> 
        <img style={{width:'100%',padding:'10px 20px 10px 20px',boxSizing:'border-box',borderBottom:'1px solid #dcdcdc'}} 
        src={downBtn} alt='' onClick={()=>window.open('https://play.google.com/store/apps/details?id=tc.wo.pulse.community')}/>
        <img style={{width:'100%',padding:'10px 20px 10px 20px',boxSizing:'border-box',borderBottom:'1px solid #dcdcdc'}} 
        src={downBtn2} alt='' onClick={()=>window.open('https://apps.apple.com/kr/app/%EA%B3%B1%EB%B9%BC%EA%B8%B0/id1621478258')}/> 
    </div>)
    
}

export default Qr