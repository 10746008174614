
import { observer } from 'mobx-react'
import { useState } from "react"
import pulse from './pulse'
import Dialog from '@mui/material/Dialog'
import imgCancelWh from '../resource/cancel_ffffff.svg'  
import imgLogo from '../resource/pulselogo_323232_bold.svg'
import imgLogoWh from '../resource/pulselogo_ffffff_bold.svg'
import imgNav from '../resource/nav_323232.svg'
import imgNavWh from '../resource/nav_ffffff.svg'
import home from '../store/home'
import '../main.css'
import './pulse.css'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay, Mousewheel } from "swiper";
import "swiper/swiper.min.css";  
import 'swiper/css/pagination'; 
import SwiperMain from './SwiperMain'
import '../swiper.css'
import SwiperPortfolio from './SwiperPortfolio'
import SwiperContact from './SwiperContact'
import SwiperBottom from './SwiperBottom'

SwiperCore.use([Autoplay])
SwiperCore.use([Mousewheel])

const Pulse = observer(()=>{
 
    const [nav,setNav] = useState(false)  

    const [swiper,setSwiper] = useState(null)
    const [pageNumber,setPageNumber] = useState(0)  
    
   

    return(
    <div className='mainLayout' 
    // style={{backgroundColor:'#0d090b'}}
    >
        {pageNumber===0 || pageNumber===3?
            <div className='topMenuFullSizeLayout'>
                <div className='pageWidthFullSize'> 
                    {pageNumber===0 || home.pageWidth!=='mobile'?
                    <img src={imgLogoWh} alt='' className='mainLogo' onClick={()=>swiper.slideTo(0)}/>:<></>}
                    {home.pageWidth!=='mobile'?
                        <div className={'menuBar'} style={{alignItems:'center'}}>
                            <div className='menuBtnWh threeMenu' style={{color:pageNumber===0?'#ffffff':'#ffffff70'}} onClick={()=>swiper.slideTo(0)}>HOME</div>
                            <div className='menuBtnWh threeMenu' style={{color:pageNumber===1?'#ffffff':'#ffffff70'}} onClick={()=>swiper.slideTo(1)}>PROJECT</div>
                            <div className='menuBtnWh threeMenu' style={{color:pageNumber>1?'#ffffff':'#ffffff70'}} onClick={()=>swiper.slideTo(2)}>CONTACT</div>
                            <img src={imgNavWh} className='menuBarLogo' style={{marginLeft:40}} alt='' onClick={()=>setNav(true)}/>
                        </div> 
                        :
                        <div className={'menuBar'}>
                            <img src={imgNavWh} className='menuBarLogo' alt='' onClick={()=>setNav(true)}/>
                        </div> 
                    }                    
                </div>
            </div>
            :
            <div className='topMenuFullSizeLayout'>
                <div className='pageWidthFullSize'> 
                    {home.pageWidth!=='mobile'?
                        <img src={imgLogo} alt='' className='mainLogo' onClick={()=>swiper.slideTo(0)}/>
                    :<></>}
            
                    {home.pageWidth!=='mobile'?
                        <div className={'menuBar'} style={{alignItems:'center'}}>
                            <div className='menuBtnBk threeMenu' style={{color:pageNumber===0?'#323232':'#32323270'}} onClick={()=>swiper.slideTo(0)}>HOME</div>
                            <div className='menuBtnBk threeMenu' style={{color:pageNumber===1?'#323232':'#32323270'}} onClick={()=>swiper.slideTo(1)}>PROJECT</div>
                            <div className='menuBtnBk threeMenu' style={{color:pageNumber>1?'#323232':'#32323270'}} onClick={()=>swiper.slideTo(2)}>CONTACT</div>
                            <img src={imgNav} className='menuBarLogo' style={{marginLeft:40}} alt='' onClick={()=>setNav(true)}/>
                        </div> 
                        :
                        <div className={'menuBar'}>
                            <img src={imgNav} alt='' className='menuBarLogo' onClick={()=>setNav(true)}/>
                        </div> 
                    }                    
                </div>
            </div>
        }

         

 
        
        <Swiper   
        onSwiper = {setSwiper}
        style={{top:0,left:0,width:'100vw',height:'100vh'}}
            speed={1000}
            modules={[Navigation, Pagination, ]} 
            direction='vertical' 
            mousewheel= {true}
             
            onSlideChange={(e) =>  
                setPageNumber(e.realIndex)
            }
            autoHeight={true} 
            slidesPerView={1} 
            initialSlide={0}                          
            loop={false}
            loopedSlides={1}
            observer={true}
            observeParents={true}
            resistance={true} >
            
                
                <SwiperSlide>
                    <SwiperMain/>   
                </SwiperSlide> 
 

                <SwiperSlide>
                    <SwiperPortfolio list={pulse.portfolio.list}/>   
                </SwiperSlide> 

                <SwiperSlide >
                    <SwiperContact/>  
                </SwiperSlide>  



                <SwiperSlide >
                    <SwiperBottom/>
                    {/* <div style={{height:'100%' }}/> */} 
                </SwiperSlide>  

        </Swiper>

       
      
            
        <Dialog className={home.widthClassname} open={nav} onClick={()=>setNav(false)}> 
            <div className='dialogNavBk fontSuit'>
                <div className='dialogTopMenuLayout'>
                    <div className='pageWidthFullSize'>
                        {/* <img src={imgLogoWh} alt='' className='mainLogo'/> */}
                        <div className={'menuBar'}>
                            <img src={imgCancelWh} alt='' className='menuBarLogo' onClick={()=>setNav(false)}/>
                        </div>                 
                    </div>
                </div>

                <div className='dialogWidth' style={{flexDirection:'column',alignItems:'flex-start',color:'#ffffff'}}>
       
                    <div className={pageNumber===0?'navMenuCkOn korTextSize3':'navMenuCkOff korTextSize2'} onClick={()=>swiper.slideTo(0)}>
                        HOME
                    </div>
                    <div className={pageNumber===1?'navMenuCkOn korTextSize3':'navMenuCkOff korTextSize2'} onClick={()=>swiper.slideTo(1)}>
                        PROJECT
                    </div>
                    <div className={pageNumber>1?'navMenuCkOn korTextSize3':'navMenuCkOff korTextSize2'} onClick={()=>swiper.slideTo(2)}>
                        CONTACT
                    </div> 
                 
                </div> 

                <div className='dialogWidth'
                style={{height:150,maxHeight:150,width:'100%',color:'#ffffff',display:'flex',flexDirection:'row'}}>
                    <div style={{display:'flex',flexDirection:'column',height:150,justifyContent:'flex-end',alignItems:'flex-start',textAlign:'left',padding:'20px 0px 30px 0px',boxSizing:'border-box',
                    fontSize:12,flex:1}}>
                        {/* <div style={{fontSize:11}}>T.041-667-5001 E.Leeinbum@gmail.com</div> */}
                        <div style={{fontWeight:500,color:'#969696',marginTop:2}}>Copyright© 2020 Pulse All rights reserved</div>
                    </div>

                    {/* <div style={{display:'flex',minWidth:'fit-content',flexDirection:'column',height:150,justifyContent:'flex-end'
                    ,boxSizing:'border-box',paddingBottom:15}}>
                        <img src={icInstar} alt='' style={{width:25,height:25,margin:'10px 0px 15px 10px'}}/>
                        <img src={icCall} alt='' style={{width:25,height:25,margin:'10px 0px 15px 10px'}}/>
                    </div> */}
                </div>
                
            </div>
        </Dialog>
 

        
      
        {/* {imgEnd?
        <div className={home.pageWidth==='mobile'?'mTopMenuLayout':'topMenuLayout'}>
            <div className={home.pageWidth==='pc'?'pageWidth topMenuBar':home.pageWidth==='bigPc'?'bPageWidth topMenuBar':'mPageWidth topMenuBar'}>
                <img src={imgLogo} alt='' className='mainLogo'/>
                {home.pageWidth!=='mobile'?
                    <div className={'menuBar'}>
                        <div className='menuBtnBk threeMenu'  onClick={()=>handleClickMenu('./portfolio')}>PORTFOLIO</div>
                        <div className='menuBtnBk threeMenu' onClick={()=>handleClickMenu('./contact')}>CONTACT</div>
                    </div> 
                    :
                    <div className={'menuBar'}>
                        <img src={imgNav} alt='' className='menuBarLogo' onClick={()=>setNav(true)}/>
                    </div> 
                }                    
            </div>
        </div>
        :
        <div className={home.pageWidth==='mobile'?'mTopMenuLayout':'topMenuLayout'} style={{backgroundColor:'#00000000',border:0,color:'#ffffff'}}>
            <div className={home.pageWidth==='pc'?'pageWidth topMenuBar':home.pageWidth==='bigPc'?'bPageWidth topMenuBar':'mPageWidth topMenuBar'}>
                <img src={imgLogoWh} alt='' className='mainLogo'/>
                {home.pageWidth!=='mobile'?
                    <div className={'menuBar'}>
                        <div className='menuBtnWh threeMenu'  onClick={()=>handleClickMenu('./portfolio')}>PORTFOLIO</div>
                        <div className='menuBtnWh threeMenu' onClick={()=>handleClickMenu('./contact')}>CONTACT</div>
                    </div> 
                    :
                    <div className={'menuBar'}>
                        <img src={imgNavWh} className='menuBarLogo' alt='' onClick={()=>setNav(true)}/>
                    </div> 
                }                    
            </div>
        </div>}
        


        <div className='topImg'>
            <img className='topImgImg' src={imgMain} alt='' />
            <div className={home.pageWidth==='pc'?'pageWidth topImgDiv':home.pageWidth==='bigPc'?'bPageWidth topImgDiv':'mPageWidth topImgDiv'}>
                <div className='topImgContents'>

                    <div className='helloPulse' style={{fontSize:home.pageWidth==='mobile'?'30px':''}}>
                        <div style={{marginRight:5}}>Hello,</div> 
                        <div className='typingText'>pulse!</div>  
                    </div>

                    <div className='topImgContact' onClick={()=>handleClickMenu('./contact')} style={{fontSize:home.pageWidth==='mobile'?'17px':''}}>
                        {'>> '+'Go Contact'}
                    </div>
                </div>
            </div>
        </div>  
        
        <div ref={setTarget}/>



        <div className={home.pageWidth==='pc'?'pageWidth':home.pageWidth==='bigPc'?'bPageWidth':'mPageWidth'} style={{paddingTop:0}} >
            <div className={home.pageWidth==='bigPc'?'bSkillList':home.pageWidth==='mobile'?'mSkillList':'skillList'}>
                <div style={{display:'flex',flexDirection:'column',padding:10,boxSizing:'border-box',alignItems:'center',justifyContent:'center'}}>
                    <img src={ios} alt='' />
                    <div className='skillTitle'>javascript</div>
                    <div className='skillContents'>PhotoShop</div>
                </div> 
               
            </div>
        </div>
 
        <div className={home.pageWidth==='pc'?'pageWidth mainportfolio':home.pageWidth==='bigPc'?'bPageWidth mainportfolio':'mPageWidth mainportfolio'} >
            <div className={home.pageWidth==='mobile'?'mPortfolioTitle':'portfolioTitle'}>
                <div style={{paddingTop:10}}>PORTFOLIO</div>
                <div>PULSE의 포트폴리오를 소개합니다</div>
                <div className='portfolioLine'></div>
            </div>
        </div> 


       
 

        <div className={home.pageWidth==='pc'?'pageWidth':home.pageWidth==='bigPc'?'bPageWidth':'mPageWidth'} style={{paddingTop:0}} >
            <div className={home.pageWidth==='pc'?'portfolioList':home.pageWidth==='bigPc'?'bPortfolioList':'mPortfolioList'}>

                {list.map((val,idx)=>
                    <span className='portfolioItem' onClick={()=>handlePortfolioDialogOpen(val)} key={idx}>
                        <img src={val.MainImg} alt='' className='portfolioItemImg'/>
                        <div className='portfolioItemHover'>
                            <div className='portfolioItemHoverView'>View +</div>
                        </div>

                        <div className='portfolioItemContents'>
                            <div className='portfolioItemContentsBox'>
                                <div className='portfolioItemContentsTitle'>
                                    <div>{val.Name}</div>
                                    <div>{val.Start}</div>
                                </div> 
                                <div className='portfolioItemContentsInfo'>
                                    <div>
                                        <div className='ellipsisStyle'>
                                            {val.Contents}  
                                        </div>
                                    </div>
                                    <div>
                                        {val.Os.map((vval,vidx)=>
                                            <img src={vval} alt='' key={vidx}/>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </span>
                )}
 
            </div>
        </div>





        <div className='bottomColumn323232'>
            <div className={home.pageWidth==='pc'?'pageWidth bottomColumn323232Layout':home.pageWidth==='bigPc'?'bPageWidth bottomColumn323232Layout':'mPageWidth bottomColumn323232Layout'}  >
                <div className={home.pageWidth==='mobile'?'mBottomColumn323232Logo':'bottomColumn323232Logo'}>PULSE</div>
              
                <div className={home.pageWidth==='mobile'?'mBottomColumn323232Row':'bottomColumn323232Row'}>
                    <div>대표자 : 이인범</div>
                    <div></div>
                    <div>충청남도 서산시 안견로 292</div>
                </div>
                <div className={home.pageWidth==='mobile'?'mBottomColumn323232Row':'bottomColumn323232Row'}>
                    <div>사업자번호 : 501-23-47453</div>
                    <div></div>
                    <div>통신판매업 : 2021-충남서산-0066</div>
                </div>
                <div className={home.pageWidth==='mobile'?'mBottomColumn323232Column':'bottomColumn323232Column'}>
                    <div>tel. 041-667-5001</div>
                    <div>email. leeinbum@gmail.com</div>
                </div>

                <div className={home.pageWidth==='mobile'?'mBottomColumn323232Button':'bottomColumn323232Button'}>
                    <a className='bottomColumn323232Link' href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=5012347453">사업자정보보기</a>
                </div>
            </div>
        </div>
 

        

 

        
        <Dialog className='App' open={portfolioDialog} onClick={()=>setPortfolioDialog(false)}>
           <PortfolioDialog handlePortfolioDialogClosed={handlePortfolioDialogClosed}/>
        </Dialog> */}
    
        
    </div>)
    })

export default Pulse