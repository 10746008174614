import {observable} from 'mobx'

import pfR2r from '../resource/mainR2R2.webp'
import pfApp1 from '../resource/mainAPP1-1.webp'
import pfApp2 from '../resource/mainAPP2-1.webp'
import pfPos from '../resource/mainPOS2.webp'
import pfWeb1 from '../resource/mainWEB1-1.webp'
import window from '../resource/ic_window.svg'
import mac from '../resource/ic_ios.svg'
import ios from '../resource/ic_ios.svg'
import android from '../resource/ic_android.svg'
import linux from '../resource/ic_linux.svg'

import r2rLogo from '../resource/port_ic_r2r.svg'
import app1Logo from '../resource/port_ic_app1.webp'
import app2Logo from '../resource/port_ic_app2.webp'
import posLogo from '../resource/port_ic_pos.svg'


import r2rImg2 from '../resource/port_img_r2r2.webp'
import r2rImg3 from '../resource/port_img_r2r3.webp'
import r2rImg4 from '../resource/port_img_r2r4.webp'

import posImg1 from '../resource/port_img_pos1.webp'
import posImg3 from '../resource/port_img_pos3.webp'
import posImg4 from '../resource/port_img_pos4.webp'
import posImg5 from '../resource/port_img_pos5.webp'
import posImg6 from '../resource/port_img_pos6.webp'

import app1Img1 from '../resource/port_img_app1_1.webp'
import app1Img2 from '../resource/port_img_app1_2.webp'
import app1Img3 from '../resource/port_img_app1_3.webp'
import app1Img4 from '../resource/port_img_app1_4.webp'
import app1Img5 from '../resource/port_img_app1_5.webp'
import app1Img6 from '../resource/port_img_app1_6.webp'
import app1Img7 from '../resource/port_img_app1_7.webp'

import app2Img1 from '../resource/port_img_app2_1.webp'
import app2Img2 from '../resource/port_img_app2_2.webp'
import app2Img3 from '../resource/port_img_app2_3.webp'
import app2Img4 from '../resource/port_img_app2_4.webp'
import app2Img5 from '../resource/port_img_app2_5.webp'
import app2Img6 from '../resource/port_img_app2_6.webp'
import app2Img7 from '../resource/port_img_app2_7.webp'
import app2Img8 from '../resource/port_img_app2_8.webp'
import app2Img9 from '../resource/port_img_app2_9.webp'
import app2Img10 from '../resource/port_img_app2_10.webp'
import app2Img11 from '../resource/port_img_app2_11.webp'
import app2Img12 from '../resource/port_img_app2_12.webp'

import web1Img1 from '../resource/port_img_web1_1.webp'
import web1Img2 from '../resource/port_img_web1_2.webp'
import web1Img3 from '../resource/port_img_web1_3.webp'
import web1Img4 from '../resource/port_img_web1_4.webp'

import pcMini from '../resource/macMini.png';
import pc from '../resource/mac.png';
import phone from '../resource/phone.png';
import pos from '../resource/pos.png';

const pulse = observable({
    portfolio:{
        idx:0,
        val:[],
        list:[        
            {
                Name:'System Program', Contents:'장비 제어 프로그램',
                MainImg:pfR2r, LogoImg:r2rLogo,
                Start:'2022.09', End:'~2022.10',
                Os:[window,],
                Type:'장비프로그램',Button:'',link:'',link2:'',
                Use:['c#','Cognex VisionPro','ACS Motion Control','Visual Studio','Illustrator','Photoshop'],
                Img:[r2rImg2,r2rImg3,r2rImg4,],
                Monitor:pcMini,
            }, 
            {
                Name:'곱빼기', Contents:'배달·커뮤니티 앱',
                MainImg:pfApp1, LogoImg:app1Logo,
                Start:'2019.11~', End:'',
                Os:[android,ios,],
                Type:'어플리케이션',Button:'download',link:'https://play.google.com/store/apps/details?id=tc.wo.pulse.community',link2:'https://apps.apple.com/kr/app/%EA%B3%B1%EB%B9%BC%EA%B8%B0/id1621478258',
                Use:['javascript','typescript','css','php','java','swift','android studio','xcode','vsCode','Illustrator','Photoshop'],
                Img:[app1Img1,app1Img2,app1Img3,app1Img4,app1Img5,app1Img6,app1Img7,],
                Monitor:phone,
            },
            {
                Name:'곱빼기 홈페이지', Contents:'곱빼기 공식홈페이지',
                MainImg:pfWeb1, LogoImg:app1Logo,
                Start:'2023.02', End:'~2023.02',
                Os:[android,ios,window,mac,linux,],
                Type:'반응형 홈페이지',Button:'link',link:'https://pulse.wo.tc/Double',link2:'',
                Use:['javascript','typescript','css','php','vsCode','Illustrator','Photoshop'],
                Img:[web1Img1,web1Img2,web1Img3,web1Img4,],
                Monitor:pc,
            },
         
            {
                Name:'곱빼기 EZ', Contents:'POS 프로그램',
                MainImg:pfPos, LogoImg:posLogo,
                Start:'2019.11~', End:'',
                Os:[window,mac,linux,],
                Type:'PC 프로그램',Button:'',link:'',link2:'',
                Use:['javascript','typescript','css','php','vsCode','Illustrator','Photoshop'],
                Img:[posImg1,posImg3,posImg4,posImg5,posImg6,],
                Monitor:pos,
            },
           
            {
                Name:'곱빼기 사장님on', Contents:'곱빼기 주문접수 앱',
                MainImg:pfApp2, LogoImg:app2Logo,
                Start:'2019.11~', End:'',
                Os:[android,],
                Type:'어플리케이션',Button:'download',link:'https://play.google.com/store/apps/details?id=tc.wo.pulse.store',link2:'',
                Use:['javascript','typescript','css','php','java','android studio','vsCode','Illustrator','Photoshop'],
                Img:[app2Img1,app2Img2,app2Img3,app2Img4,app2Img5,app2Img6,app2Img7,app2Img8,app2Img9,app2Img10,app2Img11,app2Img12,],
                Monitor:phone,
            },            
          
           
        ]
    }
})
export default pulse