import './businesscard.css'

const Dayoung = ()=>{
 

    return(
    <div>
        혜림

    </div>)
    
}

export default Dayoung