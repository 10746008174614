
import { observer } from 'mobx-react'
import SwiperCore, { Autoplay } from "swiper"
import "swiper/swiper.min.css"
import './pulse.css'
import '../main.css'
import '../swiper.css'
import home from '../store/home'
import videoMain from '../resource/mainVideo114.mp4'


//3,17,25,32,35,37
SwiperCore.use([Autoplay])

const SwiperBottom = observer((props)=>{
 
     
 
    return(
        <div style={{display:'flex',flexDirection:'column',height:'100%',width:'100%'}}>

<div style={{width:'100%',height:'100%', display:'flex',flexDirection:'column'}}>
          
          <video 
              className='mainVideoLayout' 
              style={{width:'100vw',height:'calc(100vh)',filter:'brightness(0.5)  grayscale(1)',}}
              loop
              autoPlay
              muted
              >
              <source src={videoMain} type="video/mp4"/>
          </video>
      </div>

            
            {/* <div style={{backgroundColor:'#fafafa',height:'300px',minHeight:'300px',maxHeight:'300px',width:'100%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                <div style={{height:h,minHeight:h,maxHeight:h}}/>
                <div className={home.pageWidth==='pc'?'pageWidth bottomColumn323232Layout':home.pageWidth==='bigPc'?'bPageWidth bottomColumn323232Layout':'mPageWidth bottomColumn323232Layout'}  >
               
                    <div style={{fontSize:11,textAlign:'left',margin:'1px 0px 1px 10px',display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <a className='bottomColumn323232Link' href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=5012347453" 
                        style={{margin:0,padding:'5px 8px 5px 8px',color:'#5d5d5d'}}>
                            사업자정보보기
                        </a>
                    </div> 

                    <div className={home.pageWidth==='mobile'?'mBottomColumn323232Row':'bottomColumn323232Row'}>
                        <div>대표자 : 이인범</div>
                        <div></div>
                        <div>충청남도 서산시 안견로 292</div>
                    </div>
                    <div className={home.pageWidth==='mobile'?'mBottomColumn323232Row':'bottomColumn323232Row'}>
                        <div>사업자번호 : 501-23-47453</div>
                        <div></div>
                        <div>통신판매업 : 2021-충남서산-0066</div>
                    </div>
                    <div className={home.pageWidth==='mobile'?'mBottomColumn323232Column':'bottomColumn323232Column'}>
                        <div>tel. 041-667-5001</div>
                        <div>email. leeinbum@gmail.com</div>
                    </div>

                    <div className={home.pageWidth==='mobile'?'mBottomColumn323232Button':'bottomColumn323232Button'}>
                        <a className='bottomColumn323232Link' href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=5012347453">사업자정보보기</a>
                    </div>
                </div>
            </div> */}

            <div style={{position:'absolute',width:'100%',height:'100%',display:'flex',flexDirection:'column',alignItems:'center'}}>
                <div className='pageWidthFullSize' style={{flexDirection:'column',alignItems:'center',justifyContent:'center',color:'#ffffff'}}>
                    <div className='textSize3' style={{padding:'0px 10px 30px 10px',boxSizing:'border-box', textAlign:'center',fontWeight:700,}}>
                        Where your ideas come true
                    </div>


                    <div className='textSize1' style={{color:'#ffffffdd'}}>
                        당신의 아이디어가 현실이 되는 곳
                    </div>
                </div>

                <div  style={{backgroundColor:'#ffffff',height:'auto',minHeight:'auto',maxHeight:'auto',width:'100%',
                    display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column',padding:'20px 0px 20px 0px'}}>
                    
                
                <div className='pageWidthFullSize' style={{display:'flex',
                flexDirection:'row',
                alignItems:'center',justifyContent:'center'}}>


                    
                        <div style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center',height:'100%',flexDirection:'column',lineHeight:1.6,fontSize:'12px'}}>
                        

                            <div style={{display:'flex',flexDirection:home.pageWidth==='mobile'?'column':'row', textAlign:'center', alignItems:'center',justifyContent:'center',boxSizing:'border-box'}}>
                                <div style={{display:'flex',flexDirection:'row',margin:'0px 3px 0px 3px'}}>
                                    <div style={{marginRight:10,fontWeight:700,minWidth:'fit-content'}}>사업자번호</div> 501-23-47456
                                </div>
                                <div style={{display:'flex',flexDirection:'row',margin:'0px 3px 0px 3px'}}>
                                    <div style={{marginRight:10,fontWeight:700,minWidth:'fit-content'}}>통신판매업</div> 2021-충남서산-0066
                                </div> 
                            </div> 
                        

                            <div style={{marginTop:10,color:'#969696'}}>
                            Copyright© 2020 Pulse All rights reserved
                            </div>
                            
                        </div>
                        

                        
                </div>
                    
                        
                </div>
            </div>
          


       
        </div>
        )
    })

export default SwiperBottom