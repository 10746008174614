
import { observer } from 'mobx-react';

import SwiperCore, { Autoplay } from "swiper";
import "swiper/swiper.min.css"; 
import './pulse.css'
import '../main.css' 
import videoMain from '../resource/mainVideo111.mp4'
import home from '../store/home';
//11,13,14,27,28,34,9,22
//27,17,22,34,36,53,34
//81,83,85,41,89,92,97,98,104,108,110,
SwiperCore.use([Autoplay])

const SwiperMain = observer((props)=>{
 
    
    return( 
        <div className='swiperMainLayout'>
            
            <video 
                className='mainVideoLayout' 
                // style={{filter:'brightness(0.5) grayscale(0.5)',}}
                // style={{filter:'brightness(0.5)',}}
                loop
                autoPlay
                muted
                >
                <source src={videoMain} type="video/mp4"/>
            </video>   
            
            <div className='pageWidthFullSize'
                style={{position:'absolute',color:'#ffffff', display:'flex',flexDirection:'column',alignItems:'flex-start',textAlign:'left',
                height:'100%',minHeight:'100%',maxHeight:'100%',boxSizing:'border-box'}}>

                
                
                <div style={{height:'100%',minHeight:'100%',maxHeight:'100%',boxSizing:'border-box',width:'100%',
                display:'flex',flexDirection:'column',justifyContent:'center', alignItems:'flex-start',}}>
                    <div className='topMenuHeight' />
                    
                    <div style={{height:'100%',display:'flex',width:'100%',boxSizing:'border-box',flexDirection:'column',
                    padding:home.pageWidth==='mobile'?'0px 0px 50px 0px':'0px 0px 0px 0px',alignItems:'center',justifyContent:'center'}}>
                         
                        
                        <div className='textSize3' style={{padding:'10px 10px 30px 10px',boxSizing:'border-box', textAlign:'center',fontWeight:700}}>
                            Where your ideas come true
                        </div>
 
    
                        <div className='textSize1' style={{color:'#ffffffdd'}}>
                            당신의 아이디어가 현실이 되는 곳
                        </div>
                    </div>

                <div className='topMenuHeight' style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',width:'100%'}}>
                    
                    <div className='mainScrollIcon'>
                        <div className='aniScrollDown'></div>
                    </div>
                    
                    <div className='mainScrollText'>scroll</div>
                </div>

                </div>
                
            </div>
        </div>
        )
    })

export default SwiperMain