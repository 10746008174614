
import { observer } from 'mobx-react';
import imgCancel from '../resource/cancel.svg'   
import home from '../store/home';
import pulse from './pulse';
import downBtn from '../resource/downloadbtn.svg';
import downBtn2 from '../resource/downloadbtn2.svg';
import downBtn3 from '../resource/downloadbtn3.svg';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/swiper.min.css"; 
import './pulse.css'
import '../main.css' 

SwiperCore.use([Autoplay])

const PortfolioDialog = observer((props)=>{
 
    const val = pulse.portfolio.val
   
    return(
    <div className={home.pageWidth==='mobile'?'mDialogPortfolio':'dialogPortfolio'}  onClick={(e)=>e.stopPropagation()}>
        <div className='dialogPortfolioTopLayout'>
            <img src={imgCancel} alt='' onClick={()=>props.handlePortfolioDialogClosed()}/>
        </div>

        <div style={{height:68,minHeight:68}}/>

        <div className='dialogPortfolioLayout'>
           
            <div className='dialogPortfolioTitleBar'> 
                    <img src={val.LogoImg} alt='' className='dialogPortfolioLogoImg'/>
                    <div className='dialogPortfolioTitleBox'>
                        <div className='dialogPortfolioTitleName korTextSize2 fontNanumSquareBold'>
                                {val.Name} 
                        </div>
                        <div className='dialogPortfolioTitleContents'>{val.Contents}</div>
                        <div className='dialogPortfolioTitleType'>
                            {val.Type!==''?
                            <div>
                                {val.Type}
                            </div>
                            :<></>}
                        </div>
                        
                    </div> 
           
            </div>
           

            <div className='dialogPortfolioInfo'>
                <div className='dialogPortfolioInfoTitle fontNanumSquareBold'>OS</div>
                <div className='dialogPortfolioInfoContents' style={{marginLeft:'-5px'}}>
                    {val.Os.map((vval,vidx)=>
                      <img src={vval} alt='' className='dialogPortfolioIcon' key={vidx}/>
                    )}
                </div>

                <div className='dialogPortfolioInfoTitle fontNanumSquareBold'>사용 언어 / 프로그램</div>
                <div className='dialogPortfolioInfoContents'>
                    {val.Use.map((vval,vidx)=>
                        <div key={vidx} className='dialogPortfolioItem'>
                            {vval} 
                            {vidx===val.Use?.length-1?'':','}
                        </div>
                    )}
                </div>
               
                

                <div className='dialogPortfolioInfoTitle fontNanumSquareBold'>프로젝트 기간</div>
                <div className='dialogPortfolioInfoContents'>
                    {val.Start}{val.End}
                </div>

                <div className='dialogPortfolioInfoBtnLayout'>

                    {val.link!==''?
                        val.Button === 'download'?
                            <img src={downBtn} alt='' className='dialogPortfolioInfoBtn' onClick={()=>window.open(val.link)}/>
                            :
                            <img src={downBtn3} alt='' className='dialogPortfolioInfoBtn' onClick={()=>window.open(val.link)}/>
                        :<></>
                    }

                    {val.link2!==''?
                        <img src={downBtn2} alt='' className='dialogPortfolioInfoBtn' onClick={()=>window.open(val.link2)}/>
                        :
                        <></>
                    }
                     
                   
                </div>

                {/* <div className='dialogPortfolioInfoTitle' style={{paddingTop:10}}>
                    Preview                    
                </div>

                <div className='scrollIcon'>
                    <div className='scrollArrowStep3'>{'<'}</div>
                    <div className='scrollArrowStep2'>{'<'}</div>
                    <div className='scrollArrowStep1'>{'<'}</div>
                    <div className='scrollText'>scroll</div>
                    <div className='scrollArrowStep1'>{'>'}</div>
                    <div className='scrollArrowStep2'>{'>'}</div>
                    <div className='scrollArrowStep3'>{'>'}</div>
                </div> */}
                
            </div>


            <div className='dialogPortfolioImgLayout' style={{maxWidth:val.Type==='어플리케이션'?'400px':'calc(100% - 20px)',}}>

                    
                    <img src={val.Monitor} alt='' 
                        className='dialogPortfolioMonitorImg'/>

                    <Swiper
                        modules={[Navigation, Pagination, ]}  
                        slidesPerView={1} 
                        initialSlide={0}                          
                        loop={true}
                        loopedSlides={1}
                        observer={true}
                        observeParents={true}
                        autoplay={
                        {delay:2000,
                        disableOnInteraction:true}}>
                
                        {val.Img.map((vval,vidx)=>
                            <SwiperSlide key={vidx} >
                                <img src={vval} alt='' style={{width:'100%',maxWidth:'100%',height:'auto',objectFit:'cover', }} key={vidx}/>
                            </SwiperSlide>
                        )}

                    </Swiper>
                
            </div> 

        </div>


    </div>)
    })

export default PortfolioDialog