import '../Main/main.css'
import '../input.css'
import { useEffect, useRef, useState } from 'react';
import ckOn from '../resource/ckbox_323232.svg';
import ckOff from '../resource/ckbox_dcdcdc.svg';
import imgBack from '../resource/cancel.svg';
import axios from 'axios';
import { runInAction } from 'mobx';
import home from '../store/home';
import imgNull from '../resource/null.svg';
import { Snackbar } from '@mui/material';


const Help = ()=>{
 
    const [pageWidth,setPageWidth] = useState('bigPc');
    const [agreement,setAgreement] = useState(false);
    const [agreeCk,setAgreeCk] = useState(false);
    const [addOk,setAddOk] = useState(0);

    const [toast,setToast] = useState(false);
    const [toastMsg,setToastMsg] = useState(false);
    const refEmail = useRef()
    const refContact = useRef()
    const refTitle = useRef()
    const refMsg = useRef()


    useEffect(()=>{
        function onResize(){
            if(window.innerWidth>1200){
                setPageWidth('bigPc')
            }else if(window.innerWidth>992){
                setPageWidth('pc')
            }else if(window.innerWidth>768){
                setPageWidth('tablet')
            }else{
                setPageWidth('mobile')
            }
        }
        window.addEventListener('resize',onResize);

        return ()=>{
            window.removeEventListener('resize',onResize);
        }

    },[])

   
    useEffect(()=>{
        if(window.innerWidth>1200){
            setPageWidth('bigPc')
        }else if(window.innerWidth>992){
            setPageWidth('pc')
        }else if(window.innerWidth>768){
            setPageWidth('tablet')
        }else{
            setPageWidth('mobile')
        }

    },[pageWidth]);

    const handleClosed = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        } 
       
        setToast(false)
        setToastMsg('')

        if(addOk){
            runInAction(()=>{
                home.contentsSwitch = 'home'
            })
        }


        

    }

    const handleHelpCall = () => {
        if(agreeCk){
            if(refEmail.current.value !== '' && refContact.current.value !== ''){
                let postData ={
                    'method':'help',
                    'data':{
                        'method':'helpSend',
                        'msg': refMsg.current.value,
                        'email':refEmail.current.value,
                        'contact':refContact.current.value,
                        'title':refTitle.current.value
                    }
                }
                axios.post('https://seosanez.project.wo.tc/yamyam.php',JSON.stringify(postData),{ withCredentials: true}).then(
                    (response)=>{
                        console.log(response.data)
                    
                        setToast(true)
                        setToastMsg('문의 글 작성을 완료하였습니다')
                        setAddOk(1)
                        refMsg.current.value=''
                        refEmail.current.value=''
                        refContact.current.value=''
                        refTitle.current.value=''
                        setAgreeCk(false)

                        
                })
            }else{
                console.log('error')
                setToast(true)
                setToastMsg('이메일 / 연락처를 기재해 주세요')

                
            }
        }else{
            setToast(true)
            setToastMsg('필수 동의를 체크해 주세요')
        }
    }



    return(
    <div>
 


        <section>
            <div className={pageWidth==='bigPc'?'bigPc':pageWidth==='pc'?'pc':pageWidth==='tablet'?'tablet':'mobile'}
                style={{flexDirection:'column'}}>
                
               <span className={pageWidth==='mobile'?'helpContentMobile':'helpContent'} > 
                   <div>이메일주소</div>
                   <input type='email'  ref={refEmail}  placeholder='(필수) 수신 가능한 이메일주소' className='inputText' style={{width:pageWidth==='mobile'?'100%':'90%'}}></input>
               </span>

               <span className={pageWidth==='mobile'?'helpContentMobile':'helpContent'} > 
                   <div>연락처</div>
                   <input type='number' ref={refContact}  placeholder='(필수) 수신 가능한 연락처' className='inputText' style={{width:pageWidth==='mobile'?'100%':'90%'}}></input>
               </span>

               <span className={pageWidth==='mobile'?'helpContentMobile':'helpContent'} > 
                   <div>문의제목</div>
                   <input type='text' ref={refTitle}  placeholder='제목' className='inputText' style={{width:pageWidth==='mobile'?'100%':'90%'}}></input>
               </span>

               <span className={pageWidth==='mobile'?'helpContentMobile':'helpContent'} > 
                   <div>문의내용</div>
                   <textarea type='text' ref={refMsg}  rows="10" placeholder='내용' className='inputText' style={{width:pageWidth==='mobile'?'100%':'90%'}}></textarea>
               </span>


                <div className='helpMsg'>기재해주신 이메일 혹은 연락처로 빠른 시일내에 답변 드리도록 하겠습니다.</div>

                <div className='helpCk'>
                    <span onClick={()=>setAgreeCk(agreeCk?false:true)}><img src={agreeCk?ckOn:ckOff} alt=''/>(필수) 개인정보 수집 및 이용동의</span>
                    <span><div onClick={()=>setAgreement(true)}>약관보기</div></span>
                </div> 
                <div className='helpCall' onClick={()=>handleHelpCall()}>
                    문의하기
                </div>

                
            </div>

            
        </section>
        
        <div className={agreement?"dialog-on":"display-none"} onClick={()=>setAgreement(false)}>
       
              
            <div className='dialogLayout' onClick={(e)=>e.stopPropagation()} style={{width:pageWidth==='mobile'?'80%':500}}>
                <div className="dialog-top" style={{width:pageWidth==='mobile'?'80%':500,}}>
                    <img src={imgBack} onClick={()=>setAgreement(false)} alt=""/>
                    <div>개인정보 수집 · 이용 동의</div>
                    <img src={imgNull} alt=''/>
                 </div>
                <span style={{marginTop:60,textAlign:'left'}}>
                  
                     
                
                        PULSE는 원활한 서비스 제공을 위해 최소한의 범위내에서 아래와 같이 개인정보를 수집,이용 합니다.

                        <br/><br/>
                        <div className='boldtext'>1. 수집항목</div> 

                        ⓐ 이메일을 통한 회원가입 - 닉네임,이메일주소,비밀번호,휴대폰번호
                        <br/><br/>ⓑ 타사 계정을 이용한 회원가입
                        <br/>- 닉네임,이메일주소,휴대전화번호
                        <br/>- 제3자로부터 제공받는 개인정보
                        <br/>가. 네이버 : 이메일주소, 네이버 ID코드, 닉네임
                        <br/>나. Apple ID : 이메일주소, Apple ID코드
                        <br/>다. 카카오톡 : 이메일주소, 카카오ID코드, 닉네임

                        <br/><br/>ⓒ 본인 인증 시(본인 또는 법정 대리인) - 이름, 휴대전화번호, CI, DI, 생년월일, 성별, 내/외국인 여부

                        <br/><br/>ⓓ 주문 서비스 이용시 - 휴대전화번호, 주소

                        <br/><br/>ⓔ 자동 수집 정보 - IP주소, 쿠키, 방문기록, 서비스 이용기록, 기기정보(기기고유번호,OS버전,모델명,제조사정보 등),광고ID,통신기록 등

                        <br/><br/>곱빼기 서비스 이용 중 또는 이벤트 응모 및 제휴서비스 신청 과정에서 개인정보 수집이 발생할 수 있습니다.
                        <br/>개인정보를 수집하는 경우 필요 시점에 동의를 거쳐 개인정보를 수집 이용합니다.

                        <br/><br/>
                        <div className='boldtext'>2. 수집 이용 목적</div> 


                        - 본인 및 법정대리인의 회원가입 의사, 동의 의사 확인
                        <br/>- 회원제 서비스 제공
                        <br/>- 회원관리
                        <br/>- 회원 식별, 본인인증, 성인인증
                        <br/>- 서비스 제공 및 관리(주문/결제/교환/반품/취소 등)
                        <br/>- 서비스 개선
                        <br/>- 신규 서비스 개발
                        <br/>- 고객상담
                        <br/>- 고지사항 전달
                        <br/>- 불법 및 부정 이용방지, 부정 사용자 차단 및 관리
                        <br/>- 이용기록 통계 및 분석
                        <br/>- 서비스 만족도 조사 및 관리
                        <br/>- 이벤트 정보 및 참여기회 제공
                        <br/>- 광고성 정보 제공 등 마케팅 및 프로모션


                        <br/><br/>
                        <div className='boldtext'>3. 보유 및 이용기간</div> 

                        - 회원탈퇴 후 바로 파기 (관련법령에 따라 보관되는 정보는 예외)
                        <br/>- 단, 회원가입 남용, 곱빼기 부정 사용(허위리뷰, 이벤트 부정 혜택 등) 확인 및 방지를 위해 휴대전화번호, 이메일주소, 기기정보, CI,DI는 회원탈퇴 3년 후 파기합니다.
                        <br/><br/>
                </span>
            </div>
        
        </div>
            
        <div  onClick={handleClosed} onTouchStart={handleClosed} >
            <Snackbar open={toast}   autoHideDuration={3000}   onClick={handleClosed}  onClose={handleClosed}  onTouchStart={handleClosed}  style={{height:'100%'}}>
                <div  style={{backgroundColor:'#00000090',borderRadius:'25px',color:'#ffffff',paddingTop:'8px',paddingBottom:'8px',paddingLeft:'15px',paddingRight:'15px',fontSize:'16px'}}>
                {toastMsg}
                </div>
            </Snackbar> 
        </div>


    </div>)
    
}

export default Help