
import { observer } from 'mobx-react';
import './pulse.css'
import { useState } from 'react';
import imgCancel from '../resource/cancel.svg'  
import imgNull from '../resource/null.svg';
import imgLogo from '../resource/pulselogo_323232.svg'
import imgMain from '../resource/mainimg5.jpg'
import imgNav from '../resource/nav_323232.svg'
import { useNavigate } from 'react-router-dom'
import home from '../store/home';
import ckOn from '../resource/ckbox_323232.svg';
import ckOff from '../resource/ckbox_dcdcdc.svg';
import '../main.css' 
import Dialog from '@mui/material/Dialog';

const PulseContact = observer(()=>{
 
    
    const navigate = useNavigate()
    const [nav,setNav] = useState(false)
    const [contactCategory,setContactCategory] = useState(0)
    const [agreement,setAgreement] = useState(false);
    const [agreeCk,setAgreeCk] = useState(false);

    const handleClickMenu = (url) => {
        navigate(url)
        setNav(false)
    }

    const handleHelpCall = () => {
       
    }

    return(
    <div className='mainLayout'>

        <div className={home.pageWidth==='mobile'?'mTopMenuLayout':'topMenuLayout'}>
            <div className={home.pageWidth==='pc'?'pageWidth topMenuBar':home.pageWidth==='bigPc'?'bPageWidth topMenuBar':'mPageWidth topMenuBar'}>
                <img src={imgLogo} alt='' className='mainLogo' onClick={()=>handleClickMenu('../')}/>
                {home.pageWidth!=='mobile'?
                    <div className={'menuBar'}>
                        <div className='menuBtnBk threeMenu'  onClick={()=>handleClickMenu('../portfolio')}>PORTFOLIO</div>
                        <div className='menuBtnOrg threeMenu'>CONTACT</div>
                    </div> 
                    :
                    <div className={'menuBar'}>
                        <img src={imgNav} className='menuBarLogo' alt='' onClick={()=>setNav(true)}/>
                    </div> 
                }                    
            </div>
        </div>


        {home.pageWidth==='mobile'?
            <div style={{height:70,minHeight:70,maxHeight:70}}/>
            :
            <div style={{height:100,minHeight:100,maxHeight:100}}/>        
        }

        <div className='InPageTopImg'>
            <img className='InPageTopImgImg' src={imgMain} alt='' />
            <div className='InPageTopImgDiv'>
                <div>제작문의</div>
                <div>Contact</div>
            </div>
        </div>  

        

        <div className={home.pageWidth==='pc'?'pageWidth contactStyle':home.pageWidth==='bigPc'?'bPageWidth contactStyle':'mPageWidth contactStyle'}>
 
            <div className='contactLayout' style={{flexDirection:home.pageWidth==='mobile'?'column':''}}>
                <div className='contactLeft' style={{padding:home.pageWidth==='mobile'?'0px':'0px 10px 0px 0px'}}>
                    
                    <div className='contactBox'>
                        <div className='contactBoxTitle'>
                            담당자 이름
                        </div>
                        <input type="text"/>
                    </div>
                    <div className='contactBox'>
                        <div className='contactBoxTitle'>
                            담당자 연락처
                        </div>
                        <input type="text"/>
                    </div>
                    <div className='contactBox'>
                        <div className='contactBoxTitle'>
                            담당자 휴대전화번호
                        </div>
                        <input type="text"/>
                    </div>
                    <div className='contactBox'>
                        <div className='contactBoxTitle'>
                            담당자 이메일
                        </div>
                        <input type="text"/>
                    </div>
                </div>  
                <div className='contactRight' style={{padding:home.pageWidth==='mobile'?'0px':'0px 0px 0px 10px'}}>
                    <div className='contactBox'> 
                        <div className='contactBoxTitle'>
                            제작형태
                        </div>
                        <div className='contactCategory'>
                            <div className='contactCategoryItem' onClick={()=>setContactCategory(1)}>
                                <button className={contactCategory===1?'contactCkOn':'contactCkOff'}>어플</button>
                            </div>
                            <div className='contactCategoryItem' onClick={()=>setContactCategory(2)}>
                                <button className={contactCategory===2?'contactCkOn':'contactCkOff'}>홈페이지</button>
                            </div>
                            <div className='contactCategoryItem' onClick={()=>setContactCategory(3)}>
                                <button className={contactCategory===3?'contactCkOn':'contactCkOff'}>장비 소프트웨어</button>
                            </div>
                            <div className='contactCategoryItem' onClick={()=>setContactCategory(4)}>
                                <button className={contactCategory===4?'contactCkOn':'contactCkOff'}>기타</button>
                            </div>
                        </div>
                    </div>
                    <div className='contactBox' style={{marginTop:'-5px'}}>
                        <div className='contactBoxTitle'>
                            상세내용
                        </div>
                        <textarea type="text"/>
                    </div>
                </div>
            </div>

            <div className='contactBottom' style={{flexDirection:home.pageWidth==='mobile'?'column':'row',}}>
                <div className='contactBottomContents'>
                    <div className='contactMsg'>
                        기재해주신 이메일 혹은 연락처로 빠른 시일내에 답변 드리도록 하겠습니다.
                    </div>

                    <div className='contactAgreeCk'>
                        <div onClick={()=>setAgreeCk(agreeCk?false:true)}>
                            <img src={agreeCk?ckOn:ckOff} alt=''/>
                            (필수) 개인정보 수집 및 이용동의
                        </div> 
                        <div onClick={()=>setAgreement(true)}>
                            약관보기
                        </div> 
                    </div>
                </div>


                <div className='contactCall'  style={{width:home.pageWidth==='mobile'?'100%':''}} onClick={()=>handleHelpCall()}>
                    문의하기
                </div>
            </div>

            <div style={{height:30,minHeight:30}}/>

        </div>




        <div className='bottomColumn323232'>
            <div className={home.pageWidth==='pc'?'pageWidth bottomColumn323232Layout':home.pageWidth==='bigPc'?'bPageWidth bottomColumn323232Layout':'mPageWidth bottomColumn323232Layout'}  >
                <div className={home.pageWidth==='mobile'?'mBottomColumn323232Logo':'bottomColumn323232Logo'}>PULSE</div>
              
                <div className={home.pageWidth==='mobile'?'mBottomColumn323232Row':'bottomColumn323232Row'}>
                    <div>대표자 : 이인범</div>
                    <div></div>
                    <div>충청남도 서산시 안견로 292</div>
                </div>
                <div className={home.pageWidth==='mobile'?'mBottomColumn323232Row':'bottomColumn323232Row'}>
                    <div>사업자번호 : 501-23-47453</div>
                    <div></div>
                    <div>통신판매업 : 2021-충남서산-0066</div>
                </div>
                <div className={home.pageWidth==='mobile'?'mBottomColumn323232Column':'bottomColumn323232Column'}>
                    <div>tel. 041-667-5001</div>
                    <div>email. leeinbum@gmail.com</div>
                </div>

                <div className={home.pageWidth==='mobile'?'mBottomColumn323232Button':'bottomColumn323232Button'}>
                    <a className='bottomColumn323232Link' href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=5012347453">사업자정보보기</a>
                </div>
            </div>
        </div>
 




        <div className={agreement?"dialog-on":"display-none"} onClick={()=>setAgreement(false)}>
              
            <div className='dialogLayout' onClick={(e)=>e.stopPropagation()} style={{width:home.pageWidth==='mobile'?'80%':500}}>
                <div className="dialog-top" style={{width:home.pageWidth==='mobile'?'80%':500,}}>
                    <img src={imgCancel} onClick={()=>setAgreement(false)} alt=""/>
                    <div>개인정보 수집 · 이용 동의</div>
                    <img src={imgNull} alt=''/>
                </div>
                <span style={{marginTop:60,textAlign:'left'}}>
                
                        PULSE는 원활한 서비스 제공을 위해 최소한의 범위내에서 아래와 같이 개인정보를 수집,이용 합니다.

                        <br/><br/>
                        <div className='boldtext'>1. 수집항목</div> 

                        ⓐ 이메일을 통한 회원가입 - 닉네임,이메일주소,비밀번호,휴대폰번호
                        <br/><br/>ⓑ 타사 계정을 이용한 회원가입
                        <br/>- 닉네임,이메일주소,휴대전화번호
                        <br/>- 제3자로부터 제공받는 개인정보
                        <br/>가. 네이버 : 이메일주소, 네이버 ID코드, 닉네임
                        <br/>나. Apple ID : 이메일주소, Apple ID코드
                        <br/>다. 카카오톡 : 이메일주소, 카카오ID코드, 닉네임

                        <br/><br/>ⓒ 본인 인증 시(본인 또는 법정 대리인) - 이름, 휴대전화번호, CI, DI, 생년월일, 성별, 내/외국인 여부

                        <br/><br/>ⓓ 주문 서비스 이용시 - 휴대전화번호, 주소

                        <br/><br/>ⓔ 자동 수집 정보 - IP주소, 쿠키, 방문기록, 서비스 이용기록, 기기정보(기기고유번호,OS버전,모델명,제조사정보 등),광고ID,통신기록 등

                        <br/><br/>곱빼기 서비스 이용 중 또는 이벤트 응모 및 제휴서비스 신청 과정에서 개인정보 수집이 발생할 수 있습니다.
                        <br/>개인정보를 수집하는 경우 필요 시점에 동의를 거쳐 개인정보를 수집 이용합니다.

                        <br/><br/>
                        <div className='boldtext'>2. 수집 이용 목적</div> 


                        - 본인 및 법정대리인의 회원가입 의사, 동의 의사 확인
                        <br/>- 회원제 서비스 제공
                        <br/>- 회원관리
                        <br/>- 회원 식별, 본인인증, 성인인증
                        <br/>- 서비스 제공 및 관리(주문/결제/교환/반품/취소 등)
                        <br/>- 서비스 개선
                        <br/>- 신규 서비스 개발
                        <br/>- 고객상담
                        <br/>- 고지사항 전달
                        <br/>- 불법 및 부정 이용방지, 부정 사용자 차단 및 관리
                        <br/>- 이용기록 통계 및 분석
                        <br/>- 서비스 만족도 조사 및 관리
                        <br/>- 이벤트 정보 및 참여기회 제공
                        <br/>- 광고성 정보 제공 등 마케팅 및 프로모션


                        <br/><br/>
                        <div className='boldtext'>3. 보유 및 이용기간</div> 

                        - 회원탈퇴 후 바로 파기 (관련법령에 따라 보관되는 정보는 예외)
                        <br/>- 단, 회원가입 남용, 곱빼기 부정 사용(허위리뷰, 이벤트 부정 혜택 등) 확인 및 방지를 위해 휴대전화번호, 이메일주소, 기기정보, CI,DI는 회원탈퇴 3년 후 파기합니다.
                        <br/><br/>
                </span>
            </div>
        </div>
 

        <Dialog className={home.widthClassname} open={nav} onClick={()=>setNav(false)}>
            <div className='dialogNav' onClick={(e)=>e.stopPropagation()}>
                <div className='dialogNavTitle'>
                    <img src={imgLogo} alt='' className='navMainLogo'/>
                    <div className={'menuBar'} onClick={()=>setNav(false)}>
                        <img src={imgCancel} className='menuBarLogo' alt='' onClick={()=>setNav(true)}/>
                    </div>
                </div>

                <div className={'navMenuBar'}>
                    <div className='menuBtnBk navthreeMenu' onClick={()=>handleClickMenu('../portfolio')}>PORTFOLIO</div>
                    <div className='menuBtnOrg navthreeMenu' onClick={()=>setNav(false)}>CONTACT</div>
                </div> 
            </div>
        </Dialog>
        
    </div>)
    })

export default PulseContact