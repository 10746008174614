import {Suspense, useEffect, useState} from 'react'
import { Routes ,Route} from 'react-router-dom'
import './App.css'
import './main.css'
import Main from './Main'
import Help from './Help'
import QnA from './QnA'
import Qr from './Qr'
import Hyerim from './BusinessCard/Hyerim'
import Dayoung from './BusinessCard/Dayoung'
import Inbum from './BusinessCard/Inbum'
import Pulse from './Pulse'

import PulseContact from './Pulse/PulseContact'
import PulsePortfolio from './Pulse/PulsePortfolio'
import { runInAction } from 'mobx'
import home from './store/home'
import DoublePortion from './DoublePortion'
import PersonalInformation from './Clause/PersonalInformation'

function App() {

    const [cn,setCn] = useState('')

    const setOneVh = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        
    };

    
    useEffect(()=>{

        setOneVh();
        
        function onResize(){ 

            setOneVh();

            if(window.innerWidth>1200){
                runInAction(()=>{
                    home.pageWidth = 'bigPc'
                    home.widthClassname = 'bigPcApp'
                }) 
                setCn('bigPcApp')
            }else if(window.innerWidth>899){
                runInAction(()=>{
                    home.pageWidth = 'pc'
                    home.widthClassname = 'pcApp'
                })
                setCn('pcApp')
            }else{
                runInAction(()=>{
                    home.pageWidth = 'mobile'
                    home.widthClassname = 'mobileApp'
                }) 
                setCn('mobileApp')
            }
        }
        window.addEventListener('resize',onResize);

        return ()=>{
            window.removeEventListener('resize',onResize);
        }
    },[])

    useEffect(()=>{ 
        if(window.innerWidth>1199){
            runInAction(()=>{
                home.pageWidth = 'bigPc'
                home.widthClassname = 'bigPcApp'
            }) 
            setCn('bigPcApp')
        }else if(window.innerWidth>899){
            runInAction(()=>{
                home.pageWidth = 'pc'
                home.widthClassname = 'pcApp'
            })
            setCn('pcApp')
        }else{
            runInAction(()=>{
                home.pageWidth = 'mobile'
                home.widthClassname = 'mobileApp'
            }) 
            setCn('mobileApp')
        } 
    },[home.pageWidth]);

  return (
    <div className={cn}>
        <Suspense fallback={<div style={{display:"flex",height:"100vh",width:"100vw",justifyContent:"center",alignItems:"center"}}>Loading...</div>}>
            {/* <Routes>
                <Route path="/" element={<Main/>} />
            </Routes> */}
            
            <Routes>
                <Route path="/" element={<Pulse/>} />
                <Route path="/Double" element={<DoublePortion/>}/>
                <Route path="/PersonalInformation" element={<PersonalInformation/>}/>
                <Route path="/Main" element={<Main/>} />
                <Route path="/contact" element={<PulseContact/>} />
                <Route path="/portfolio" element={<PulsePortfolio/>} />
                <Route path="/help" element={<Help/>} />
                <Route path="/qna" element={<QnA/>} />
                <Route path="/qr" element={<Qr/>} />
                <Route path="/Hyerim" element={<Hyerim/>} />
                <Route path="/Dayoung" element={<Dayoung/>} />
                <Route path="/Inbum" element={<Inbum/>} />
            </Routes>

        </Suspense>
    </div>
  );
}

export default App