import './businesscard.css'

const Hyerim = ()=>{
 

    return(
    <div style={{width:'100vw',height:'100vh',alignItems:'center',justifyContent:'center',display:'flex',flex:1,backgroundColor:'#eeeeee'}}>
        <div style={{width:'calc(100vw - 100px)',minHeight:'calc(100vh - 100px)',display:'flex',flexDirection:'column',backgroundColor:'#ffffff',
             borderRadius:10,boxShadow:'3px 5px 8px #dcdcdc'}}>

                <div>
                    박혜림
                </div>

                <div>
                    
                </div>

        </div>

    </div>)
    
}

export default Hyerim