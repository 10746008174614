import './main.css'
import { useEffect, useState } from 'react';
import imgLogo from '../resource/doubleportion/doubleportionLogo_ee8432.svg';
// import Home from '../Home';
import Help from '../Help';
import QnA from '../QnA';
import { runInAction } from 'mobx';
import home from '../store/home';
import { observer } from 'mobx-react';


const Main = observer(()=>{

    const [pageWidth,setPageWidth] = useState('bigPc');

    useEffect(()=>{
        function onResize(){
            if(window.innerWidth>1200){
                setPageWidth('bigPc')
            }else if(window.innerWidth>992){
                setPageWidth('pc')
            }else if(window.innerWidth>768){
                setPageWidth('tablet')
            }else{
                setPageWidth('mobile')
            }
        }
        window.addEventListener('resize',onResize);

        return ()=>{
            window.removeEventListener('resize',onResize);
        }

    },[])

    useEffect(()=>{
        console.log('바뀜',home.contentsSwitch)
    },[home.contentsSwitch])

   
    useEffect(()=>{
        if(window.innerWidth>1200){
            setPageWidth('bigPc')
        }else if(window.innerWidth>992){
            setPageWidth('pc')
        }else if(window.innerWidth>768){
            setPageWidth('tablet')
        }else{
            setPageWidth('mobile')
        }

    },[pageWidth]);
 
 
    const handleSwitch=(val)=>{
        runInAction(()=>{
            home.contentsSwitch = val
        })
    }





    return(
    <div className='main'>

        <nav>
            <div className={pageWidth==='bigPc'?'bigPcTop':pageWidth==='pc'?
            'pcTop':pageWidth==='tablet'?'tabletTop':'mobileTop'}>
                <img src={imgLogo} alt='' onClick={()=>handleSwitch('home')}/> 
                <ul>
                    <li style={{marginRight:20}} onClick={()=>handleSwitch('help')}>
                        입점문의
                    </li>
                    <li onClick={()=>handleSwitch('qna')}>
                        고객센터
                    </li>
                    
                </ul> 
            </div>
        </nav>

        <section style={{backgroundColor:home.contentsSwitch==='home'?'#ee8432':'#ffffff'}}>
            {/* <div>
                {home.contentsSwitch==='qna'?<QnA/>
                :home.contentsSwitch==='help'?<Help/>
                :<Home/>}
            </div> */}
        </section>
 



        <footer>
            <div className={pageWidth==='bigPc'?'bigPc':pageWidth==='pc'?'pc':pageWidth==='tablet'?'tablet':'mobile'}
                 style={{marginTop:0,alignItems:'flex-start',flexDirection:pageWidth==='mobile'||pageWidth==='tablet'?'column':'row'}}>
                <div>
                    <span>
                        <span style={{fontWeight:700,marginRight:10}}>
                            PULSE
                        </span>
                            <a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=5012347453">
                            <span>(사업자정보확인) </span>
                        </a>
                    </span>
                    <span >
                        충청남도 서산시 안견로 292
                    </span>
                </div>
                <div>
                    <span>
                        대표자 : 이인범 | 사업자번호 : 501-23-47453
                    </span>
                    <span>
                        통신판매업 : 2021-충남서산-0066
                    </span>
                </div>
                <div>
                    <span>
                        대표전화 : 041-667-5001
                    </span>
                    <span>
                        대표메일 : leeinbum@gmail.com
                    </span>
                </div>
            </div>
        </footer>

    </div>)
    })

export default Main