
import { observer } from 'mobx-react';

import "swiper/swiper.min.css"; 
import './pulse.css'
import '../main.css'
import '../swiper.css'
import home from '../store/home';

import icInstar from '../resource/nav_instar_323232.svg'
import icCall from '../resource/nav_call_323232.svg' 
import NaverMap from '../NaverMap'

//43,50,51,74,88

const SwiperContact = observer((props)=>{
 


    return(
        <div className='swiperMainLayout'  style={{maxHeight:'100%'}}>
            <div className='pageWidthFullSize' style={{display:'flex',flexDirection:'column',height:'100%',alignItems:'flex-start', }}>

            
                {home.pageWidth==='mobile'?
                        <div style={{height:80,minHeight:80,maxHeight:80}}/>
                    :<div style={{height:120,minHeight:120,maxHeight:120}}/>
                }

                <div  style={{height:'100%',maxHeight:900,width:'100%',display:'flex',flexDirection:'row',alignItems:'center',textAlign:'center',justifyContent:'flex-start',boxSizing:'border-box'}}>
        
                    
                    <div style={{height:'100%',display:'flex',flex:1,minWidth:300,flexDirection:'column',alignItems:'flex-start',textAlign:'left',justifyContent:'center',padding:'0px 5px 0px 5px',boxSizing:'border-box',}}>
                        {/* <div className='korTextSize3'>성공적인 비지니스의 시작,</div>
                        <div className='korTextSize3'> PULSE와 함께 하세요.</div> */} 
                        <div className='korTextSize3' style={{color:'#323232',fontWeight:600}}>충청남도 서산시<br/>안견로 292, PULSE</div>
                        <div  style={{height:30,minHeight:20}}/>
                        <div className='korTextSize1' style={{color:'#323232'}}>PULSE, 292, Angyeon-ro,<br/> Seosan-si, Chungcheongnam-do</div>
                        <div style={{height:55,minHeight:30}}/>
                        <div className='korTextSize1' style={{padding:'0px 0px 5px 0px',display:'flex',flexDirection:'row'}}><div style={{fontWeight:700,marginRight:10,width:50,minWidth:50,maxWidth:50,color:'#323232'}}>CEO.</div>이인범</div>
                        <div className='korTextSize1' style={{padding:'0px 0px 5px 0px',display:'flex',flexDirection:'row'}}><div style={{fontWeight:700,marginRight:10,width:50,minWidth:50,maxWidth:50,color:'#323232'}}>TEL.</div>041. 667. 5001</div>
                        <div className='korTextSize1' style={{display:'flex',flexDirection:'row',wordBreak:'break-word'}}><div style={{fontWeight:700,marginRight:10,width:50,minWidth:50,maxWidth:50,color:'#323232'}}>EMAIL.</div>Leeinbum@gmail.com</div>

                        {home.pageWidth==='mobile'?
                    
                            <div style={{height:'100%',minHeight:30,width:'calc(100vw - 50px)',margin:'25px 0px 30px 0px',}}>
                                <NaverMap isTouchStarted={true} latitude={36.7773292} longitude={126.4512495}/>
                            </div> : <div style={{display:'flex',flex:1,minHeight:55}}/>
                        }

                        <div style={{display:'flex',flexDirection:'row',width:'100%',justifyContent:'flex-start',marginTop:0,marginBottom:10}}>
                            <img src={icCall} alt='' style={{width:home.pageWidth==='mobile'?22:25,height:'auto',marginRight:25}} onClick={()=>document.location.href='tel:010-9486-1351'}/>
                            <img src={icInstar} alt='' style={{width:home.pageWidth==='mobile'?22:25,height:'auto'}} onClick={()=>window.open('https://www.instagram.com/pulse_x2')}/>
                        </div>

                    </div>

                    {home.pageWidth==='mobile'?<></>:
                    
                        <div style={{height:'100%',width:'calc(100vw - 200px)',maxWidth:1500,marginLeft:40,}}>
                            <NaverMap isTouchStarted={true} latitude={36.7773292} longitude={126.4512495}/>
                        </div>
                    }
                </div>
                
                <div style={{height:50,minHeight:10}}/>
        
                
            </div>
        </div>
        )
    })

export default SwiperContact