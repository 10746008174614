
import { observer } from 'mobx-react';
import './pulse.css'
import { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import imgCancel from '../resource/cancel.svg'  
import imgLogo from '../resource/pulselogo_323232.svg'
import imgMain from '../resource/mainimg4.jpg'
import imgNav from '../resource/nav_323232.svg'
import { useNavigate } from 'react-router-dom'
import home from '../store/home';
import { runInAction } from 'mobx';
import pulse from './pulse';
import PortfolioDialog from './PortfolioDialog';
import '../main.css'

const PulsePortfolio = observer(()=>{
 
    const [nav,setNav] = useState(false)
    const navigate = useNavigate()
    const list = pulse.portfolio.list
    const [portfolioDialog,setPortfolioDialog] = useState(false) 
   
    useEffect(()=>{
        return(()=>{
            window.onpopstate = {}
        })
    },[])

    useEffect(()=>{
        if(portfolioDialog){
            window.history.pushState({ page: 1 }, "title 1", "?page=1")
        }
        window.onpopstate = handleOnBack
    },[portfolioDialog])

    const handleOnBack = ()=>{
        setPortfolioDialog(false)
    }

    const handleClickMenu = (url) => {
        navigate(url)
        setNav(false)
    }

    const handlePortfolioDialogOpen = (val) => {
        setPortfolioDialog(true)
        runInAction(()=>{
            pulse.portfolio.val = val
        })
    }
    const handlePortfolioDialogClosed = (isOpen) => {
        setPortfolioDialog(false)
    }

    return(
    <div className='mainLayout'>

        <div className={home.pageWidth==='mobile'?'mTopMenuLayout':'topMenuLayout'}>
            <div className={home.pageWidth==='pc'?'pageWidth topMenuBar':home.pageWidth==='bigPc'?'bPageWidth topMenuBar':'mPageWidth topMenuBar'}>
                <img src={imgLogo} alt='' className='mainLogo' onClick={()=>handleClickMenu('../')}/>
                {home.pageWidth!=='mobile'?
                    <div className={'menuBar'}>
                        <div className='menuBtnOrg threeMenu'>PORTFOLIO</div>
                        <div className='menuBtnBk threeMenu' onClick={()=>handleClickMenu('../contact')}>CONTACT</div>
                    </div> 
                    :
                    <div className={'menuBar'}>
                        <img src={imgNav} className='menuBarLogo' alt='' onClick={()=>setNav(true)}/>
                    </div> 
                }                    
            </div>
        </div>

   

        {home.pageWidth==='mobile'?
            <div style={{height:70,minHeight:70,maxHeight:70}}/>
            :
            <div style={{height:100,minHeight:100,maxHeight:100}}/>        
        }

        <div className='InPageTopImg'>
            <img className='InPageTopImgImg' src={imgMain} alt='' />
            <div className='InPageTopImgDiv'>
                <div>포트폴리오</div>
                <div>Portfolio</div>
            </div>
        </div>

        <div className={home.pageWidth==='pc'?'pageWidth':home.pageWidth==='bigPc'?'bPageWidth':'mPageWidth'} style={{paddingTop:20}} >
            <div className={home.pageWidth==='pc'?'portfolioList':home.pageWidth==='bigPc'?'bPortfolioList':'mPortfolioList'}>

                {list.map((val,idx)=>
                    <span className='portfolioItem' onClick={()=>handlePortfolioDialogOpen(val)} key={idx}>
                        <img src={val.MainImg} alt='' className='portfolioItemImg'/>
                        <div className='portfolioItemHover'>
                            <div className='portfolioItemHoverView'>View +</div>
                        </div>

                        <div className='portfolioItemContents'>
                            <div className='portfolioItemContentsBox'>
                                <div className='portfolioItemContentsTitle'>
                                    <div>{val.Name}</div>
                                    <div>{val.Start}</div>
                                </div> 
                                <div className='portfolioItemContentsInfo'>
                                    <div>
                                        <div className='ellipsisStyle'>
                                            {val.Contents}  
                                        </div>
                                    </div>
                                    <div>
                                        {val.Os.map((vval,vidx)=>
                                            <img src={vval} alt='' key={vidx}/>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </span>
                )}
 
            </div>
        </div>



        <div className='bottomColumn323232'>
            <div className={home.pageWidth==='pc'?'pageWidth bottomColumn323232Layout':home.pageWidth==='bigPc'?'bPageWidth bottomColumn323232Layout':'mPageWidth bottomColumn323232Layout'}  >
                <div className={home.pageWidth==='mobile'?'mBottomColumn323232Logo':'bottomColumn323232Logo'}>PULSE</div>
              
                <div className={home.pageWidth==='mobile'?'mBottomColumn323232Row':'bottomColumn323232Row'}>
                    <div>대표자 : 이인범</div>
                    <div></div>
                    <div>충청남도 서산시 안견로 292</div>
                </div>
                <div className={home.pageWidth==='mobile'?'mBottomColumn323232Row':'bottomColumn323232Row'}>
                    <div>사업자번호 : 501-23-47453</div>
                    <div></div>
                    <div>통신판매업 : 2021-충남서산-0066</div>
                </div>
                <div className={home.pageWidth==='mobile'?'mBottomColumn323232Column':'bottomColumn323232Column'}>
                    <div>tel. 041-667-5001</div>
                    <div>email. leeinbum@gmail.com</div>
                </div>

                <div className={home.pageWidth==='mobile'?'mBottomColumn323232Button':'bottomColumn323232Button'}>
                    <a className='bottomColumn323232Link' href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=5012347453">사업자정보보기</a>
                </div>
            </div>
        </div>
 
 

        <Dialog className={home.widthClassname} open={nav} onClick={()=>setNav(false)}>
            <div className='dialogNav' onClick={(e)=>e.stopPropagation()}>
                <div className='dialogNavTitle'>
                    <img src={imgLogo} alt='' className='navMainLogo'/>
                    <div className={'menuBar'} onClick={()=>setNav(false)}>
                        <img src={imgCancel} className='menuBarLogo' alt='' onClick={()=>setNav(true)}/>
                    </div>
                </div>

                <div className={'navMenuBar'}>
                    <div className='menuBtnOrg navthreeMenu' onClick={()=>setNav(false)} >PORTFOLIO</div>
                    <div className='menuBtnBk navthreeMenu'  onClick={()=>handleClickMenu('../contact')}>CONTACT</div>
                </div> 
            </div>
        </Dialog>

      
        <Dialog className={home.widthClassname} open={portfolioDialog} onClick={()=>setPortfolioDialog(false)}>
           <PortfolioDialog handlePortfolioDialogClosed={handlePortfolioDialogClosed}/>
        </Dialog>

        

    </div>)
    })

export default PulsePortfolio