import '../Main/main.css'
import { useEffect, useState } from 'react'
import GridList from '@mui/material/Grid'
import imgUp from '../resource/arrowup_b7b7b7.svg'
import imgDown from '../resource/arrowdown_b7b7b7.svg'

const QnA = ()=>{

    const [pageWidth,setPageWidth] = useState('bigPc');

    useEffect(()=>{
        function onResize(){
            if(window.innerWidth>1200){
                setPageWidth('bigPc')
            }else if(window.innerWidth>992){
                setPageWidth('pc')
            }else if(window.innerWidth>768){
                setPageWidth('tablet')
            }else{
                setPageWidth('mobile')
            }
        }
        window.addEventListener('resize',onResize);

        return ()=>{
            window.removeEventListener('resize',onResize);
        }

    },[])

   
    useEffect(()=>{
        if(window.innerWidth>1200){
            setPageWidth('bigPc')
        }else if(window.innerWidth>992){
            setPageWidth('pc')
        }else if(window.innerWidth>768){
            setPageWidth('tablet')
        }else{
            setPageWidth('mobile')
        }

    },[pageWidth]);

    const [qnaCtgrCk,setQnaCtgrCk] = useState('전체보기');
    const [qnaSrl,setQnaSrl] = useState(15);

    const [qnaCtgr] = useState({result:[
        {ctgrSrl:'0',ctgr:'전체보기',select:true}, {ctgrSrl:'1',ctgr:'회원관련',select:false}, {ctgrSrl:'2',ctgr:'주문관련',select:false}, {ctgrSrl:'3',ctgr:'리뷰관련',select:false},
        {ctgrSrl:'4',ctgr:'어플이용',select:false}, {ctgrSrl:'5',ctgr:'아바타',select:false}, {ctgrSrl:'6',ctgr:'기타문의',select:false}, {ctgrSrl:'7',ctgr:'',select:false}
    ]});
    
    
    const [pulseQnAList] = useState(   {result:[
        {qnaSrl:'0',ctgr:'회원관련',title:'아이디를 잊어버렸습니다.',
        content:'[내정보 -> 로그인 -> 아이디찾기]에서 아이디를 찾을수 있습니다.',select:false},
    
        {qnaSrl:'1',ctgr:'회원관련',title:'비밀번호를 잊어버렸습니다.',
        content:'[내정보 -> 로그인 -> 비밀번호찾기]에서 새로운 비밀번호를 등록할 수 있습니다.',select:false},

        {qnaSrl:'2',ctgr:'회원관련',title:'로그아웃 하고 싶습니다.',
        content:'[내정보]탭 맨 하단에 로그아웃 버튼이 있습니다.',select:false},

        {qnaSrl:'3',ctgr:'회원관련',title:'회원탈퇴는 어떻게 하나요?',
        content:'[내정보]탭 맨 하단에 회원탈퇴 버튼이 있습니다.',select:false},

        {qnaSrl:'4',ctgr:'회원관련',title:'회원정보를 수정하고 싶습니다.',
        content:'[내정보]탭에서 닉네임, 호칭, 아바타, 휴대폰번호를 수정할 수 있습니다.',select:false},

        {qnaSrl:'5',ctgr:'회원관련',title:'아이디를 변경하고 싶습니다.',
        content:'아이디는 변경이 불가능합니다.',select:false},

        {qnaSrl:'6',ctgr:'회원관련',title:'비밀번호를 변경하고 싶습니다.',
        content:'[내정보 -> 로그인 -> 비밀번호찾기]에서 새로운 비밀번호를 등록할 수 있습니다.',select:false},

        
        {qnaSrl:'7',ctgr:'주문관련',title:'주문을 취소하고 싶습니다.',
        content:'주문취소는 가게에서 주문을 접수하기 전에만 가능합니다.',
        content2:'이미 접수가 완료된 상태에서의 주문취소는 가게 사장님과 상의해주세요.',
        content3:'-',
        content4:'▼ 주문접수 전 주문취소 ▼',
        content5:'[주문내역 -> 취소할 주문 선택] 주문취소 클릭',select:false},
        
        {qnaSrl:'8',ctgr:'주문관련',title:'주문내용을 변경하고 싶습니다.',
        content:'주문내용 변경은 가게에서 주문을 접수하기 전에만 가능합니다.',
        content2:'-',
        content3:'▼ 주문접수 전 주문내용 변경 ▼',
        content4:'가게 또는 고객센터로 연락 주시기 바랍니다.',select:false},

        {qnaSrl:'9',ctgr:'주문관련',title:'내 주문내역을 삭제할 수 있나요?',
        content:'주문내역 삭제는 불가능합니다.',select:false},

        {qnaSrl:'10',ctgr:'주문관련',title:'비회원이어도 주문이 가능한가요?',
        content:'비회원주문은 불가능합니다.',select:false},

        
        {qnaSrl:'11',ctgr:'리뷰관련',title:'리뷰를 등록하고 싶습니다.',
        content:'[주문내역]탭에 리뷰등록 버튼이 있습니다.',
        content2:'리뷰작성은 배달완료 시점부터 7일 이내에만 가능합니다.',select:false},

        {qnaSrl:'12',ctgr:'리뷰관련',title:'리뷰를 수정/삭제하고 싶습니다.',
        content:'[내정보 -> 리뷰함]에서 리뷰를 수정/삭제할 수 있습니다.',
        content2:'리뷰수정은 배달완료 시점부터 7일 이내에만 가능합니다.',select:false},


        {qnaSrl:'13',ctgr:'어플이용',title:'알림을 끄고싶습니다.',
        content:'[내정보 -> 어플설정]메뉴에서 알림을 on/off 할 수 있습니다.',select:false},

        
        {qnaSrl:'14',ctgr:'아바타',title:'내 아바타를 변경하고싶습니다.',
        content:'[내정보]탭 우측상단 톱니바퀴모양 아이콘을 클릭해주세요.',select:false},
    
    
    ]});
    




    return(
    <div>
  
        <section>
            <div className={pageWidth==='bigPc'?'bigPc':pageWidth==='pc'?'pc':pageWidth==='tablet'?'tablet':'mobile'}
                style={{flexDirection:'column',padding:pageWidth==='mobile' || pageWidth==='tablet'?0:'',fontSize:14.5}}>
               
               <div className='line'/>


               <div className='qnaCtgrFixed' style={{top:pageWidth==='mobile' || pageWidth==='tablet'?70:120}}>
                    <div className={pageWidth==='bigPc'?'bigPc':pageWidth==='pc'?'pc':pageWidth==='tablet'?'tablet':'mobile'} style={{margin:0}} >
                        <GridList  cols={pageWidth==='mobile' || pageWidth==='tablet'?4:8} cellHeight={50} spacing={0} className='qnaCtgr'>
                            {qnaCtgr.result.map((val,idx) => (
                                    <div key={idx} onClick={()=>idx===7?'':setQnaCtgrCk(val.ctgr)} className={qnaCtgrCk===val.ctgr?'qnaCk':'qna'}>
                                        {val.ctgr}
                                    </div>
                                ))}
                        </GridList>
                    </div>
                
                
                    <div className='grayBar'/>
                </div>

                <div style={{height:pageWidth==='mobile' || pageWidth==='tablet'?100:50}}/>

           

              {pulseQnAList.result.map((val,idx) => ( 
                  <div className='qnaList' onClick={()=>setQnaSrl(qnaSrl===val.qnaSrl?15:val.qnaSrl)}
                  style={{display:qnaCtgrCk==='전체보기'?"flex":qnaCtgrCk === val.ctgr?"flex":"none"}}>
                       
                        <div style={{display:'flex',flexDirection:'row',padding:20,margin:0,textAlign:'left',boxSizing:'border-box'}}
                             className={pageWidth==='bigPc'?'bigPc':pageWidth==='pc'?'pc':pageWidth==='tablet'?'tablet':'mobile'}>
                            <span className={qnaSrl===val.qnaSrl?'qnaTitleTextCk':'qnaTitleText'}>{val.title}</span>
                            <span className='qnaTitleImg'><img src={qnaSrl===val.qnaSrl?imgUp:imgDown} alt='' style={{width:15,maxWidth:15}}/></span>
                        </div>

                        

                        <div className='qnaContent' style={{display:qnaSrl===val.qnaSrl?'flex':'none'}}>
                            <span style={{display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'flex-start',margin:0,padding:20,boxSizing:'border-box',textAlign:'left',fontSize:14,color:'#969696'}}
                                  className={pageWidth==='bigPc'?'bigPc':pageWidth==='pc'?'pc':pageWidth==='tablet'?'tablet':'mobile'} >
                                <div>{val.content}</div>
                                <div>{val.content2}</div>
                                <div>{val.content3}</div>
                                <div>{val.content4}</div>
                                <div>{val.content5}</div>
                            </span>
                        </div>

                </div>
              ))}
 


            </div>
        </section>  

    </div>)
    
}

export default QnA